export const calculateTotalCart = (records = []) => {
  const products = records.reduce((result, record) => {
    if (Array.isArray(record.products)) return [...result, ...record.products];
    return records;
  }, []);

  return products
    .reduce((result, product) => result + Number(product.price) * product.quantity, 0)
    .toFixed(2);
};
