import { BsChevronRight } from "react-icons/bs";
import styled from "styled-components";
import { healthDailyList } from "../../configs/carousels/healthDailyList";
import HealthBox from "./HealthBox";

const HealthDaily = () => {
  return (
    <Wrapper>
      <Container>
        <Header>
          <Title>Health Daily</Title>
          <TextBox>
            All Articles
            <StyledBsChevronRight />
          </TextBox>
        </Header>
        <Blocks>
          {healthDailyList.map((health, index) => (
            <HealthBox index={index} key={health.title} data={health} />
          ))}
        </Blocks>
      </Container>
      <MobileBox>
        <MobileTitle>
          Shop faster
          <br />
          with Farmart App
        </MobileTitle>
        <MobileNote>Available on both iOS & Android</MobileNote>
        <MobileImage src="/images/app-while-img.png" />
      </MobileBox>
    </Wrapper>
  );
};

const MobileImage = styled.img``;

const MobileNote = styled.div`
  margin-top: 15px;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 1.71429;
  color: #7a7a7a;
`;

const MobileTitle = styled.div`
  font-size: 36px;
  margin-top: 70px;
  text-align: center;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -1px;
  color: #6ec1e4;
`;

const MobileBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url("/images/ezgif.com-webp-to-jpg (4).jpg");
  width: 32%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  background-position: bottom center;
`;

const Blocks = styled.div`
  border-radius: 10px;
  background-color: white;
  display: flex;
`;

const StyledBsChevronRight = styled(BsChevronRight)`
  margin-left: 5px;
`;

const TextBox = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => (props.color ? props.color : "#666")};
  transition: ease 0.3s;
  font-size: 14px;
  line-height: 1.71429;
  margin-left: 20px;
  cursor: pointer;
  &:hover {
    color: #fab528;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 38px;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: 600;
  color: #323232;
  font-size: 30px;
  line-height: 1.71429;
`;

const Container = styled.div`
  width: 68%;
  padding-right: 30px;
`;

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 100px;
`;

export default HealthDaily;
