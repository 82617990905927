import styled from "styled-components";

const CartBtn = ({ Icon, name }) => {
  const IconStyled = styled(Icon)``;
  return (
    <Wrapper>
      <Container>
        <IconStyled />
        <Name>{name}</Name>
      </Container>
    </Wrapper>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  display: inline;
  margin-left: 5px;
`;

const Wrapper = styled.div`
  font-size: 14px;
  line-height: 1.71429;
  display: inline-block;
  margin-right: 10px;
  padding: 9px 30px;
  color: #000;
  font-weight: 500;
  background-color: #f5f5f5;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  text-align: center;
  margin-bottom: 0;
  position: relative;
  text-decoration: none;
  outline: 0;
  transition: 0.5s;
`;

export default CartBtn;
