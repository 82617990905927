import { FIND_PARKING_LOT_BY_USER, REGISTER_PARKING_LOT } from "./types";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { findParkingLotByUserAct, findParkingLotByUserSuccessAct } from "./actions";
import { showNotification } from "../../utils/showNotification";
import { messageFromResponse } from "../../utils/messageFromRes";
import parkingService from "../../services/parking.service";

export function* registerParkingLot({ payload }) {
  try {
    const result = yield call(parkingService.registerParkingLot, payload);
    showNotification({ type: "success", message: messageFromResponse(result) });
    yield put(findParkingLotByUserAct(result));
  } catch (err) {
    console.log({ saga: "registerParkingLot", err });
  }
}

export function* findParkingLotByUser() {
  try {
    const result = yield call(parkingService.findByUser);
    yield put(findParkingLotByUserSuccessAct(result));
  } catch (err) {
    console.log({ saga: "findParkingLotByUser", err });
  }
}

export function* findParkingLotByUserSaga() {
  yield takeLatest(FIND_PARKING_LOT_BY_USER, findParkingLotByUser);
}

export function* registerParkingLotSaga() {
  yield takeLatest(REGISTER_PARKING_LOT, registerParkingLot);
}

export function* root() {
  yield all([findParkingLotByUserSaga(), registerParkingLotSaga()]);
}

export default root;
