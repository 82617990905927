import styled from "styled-components";

const Content = ({ title, description, action }) => {
  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <ShopNowButton onClick={action}>Shop Now</ShopNowButton>
      </Container>
    </Wrapper>
  );
};

const ShopNowButton = styled.div`
  color: #000;
  border-color: #000;
  padding: 15px 30px 15px 30px;
  background-color: #fff;
  box-shadow: none;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  transition: ease 0.5s;
  cursor: pointer;
  text-align: left;
  font-weight: 500;
`;

const Description = styled.div`
  margin-bottom: 80px;
  color: #2a4356;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
`;

const Title = styled.div`
  color: #2a4356;
  margin-bottom: 33px;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: -1px;
  text-align: left;
  font-weight: 600;
`;

const Container = styled.div`
  max-width: 84%;
  width: 84%;
  float: left;
  text-align: left;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default Content;
