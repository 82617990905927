import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { registerAct } from "../../redux/auth/actions";

const { Option } = Select;

const RegisterPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onFinish = ({ user_name, password, role }) =>
    dispatch(registerAct(user_name, password, role, navigate));
  const navigateToLogin = () => {
    navigate("/login");
  };
  return (
    <Wapper>
      <Container>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}>
          <Form.Item
            name="user_name"
            rules={[{ required: true, message: "Please input your Username!" }]}>
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}>
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item name="role" rules={[{ required: true }]}>
            <Select defaultActiveFirstOption placeholder="Role" allowClear>
              <Option value="CUSTOMER">Customer</Option>
              <Option value="STORE_OWNER">Store Owner</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <StyledLoginBtn type="primary" htmlType="submit" className="login-form-button">
              Register
            </StyledLoginBtn>
            <CenterBox>
              <a onClick={navigateToLogin}>Login now!</a>
            </CenterBox>
          </Form.Item>
        </Form>
      </Container>
    </Wapper>
  );
};
const CenterBox = styled(Button)`
  text-align: center;
  margin-top: 5px;
  width: 100%;
`;
const StyledLoginBtn = styled(Button)`
  width: 100%;
`;
const Wapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Container = styled.div`
  height: 500px;
  width: 400px;
  padding: 20px;
`;

export default RegisterPage;
