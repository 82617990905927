import { MenuStoreProvider } from "../../contexts/MenuStore";
import CustomTable from "../../components/tables";
import { useEffect } from "react";
import { tableProductConfigs } from "../../configs/tables/tableProductConfigs";
import { useDispatch, useSelector } from "react-redux";
import { getAllDishesAct } from "../../redux/restaurants/actions";

const StoreMenu = () => {
  const dispatch = useDispatch();
  const { dishes } = useSelector((state) => state.restaurants);
  useEffect(() => {
    dispatch(getAllDishesAct());
  }, []);
  return (
    <MenuStoreProvider>
      <CustomTable configs={tableProductConfigs()} data={dishes} />
    </MenuStoreProvider>
  );
};

export default StoreMenu;
