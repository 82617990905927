import uuid from "react-uuid";

export const transformImageUrl = (imgUrl) => {
  if (typeof imgUrl === "object") {
    return imgUrl;
  } else if (typeof imgUrl === "string") {
    return {
      uid: uuid(),
      name: uuid(),
      status: "done",
      url: imgUrl,
    };
  }
};
