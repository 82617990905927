import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "antd/dist/reset.css";
import LoginPage from "./views/Login";
import RegisterPage from "./views/Register";
import HomePage from "./views/Home";
import CustomLayout from "./layouts";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-multi-carousel/lib/styles.css";
import StoreDashboard from "./views/StoreDashboard";
import StoreMenu from "./views/StoreMenu";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getProfileAct } from "./redux/auth/actions";
import ProtectedRoute from "./ProtectedRoute";
import StoreParking from "./views/StoreParking";
import Cart from "./views/Cart";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfileAct());
  }, []);
  console.log(1111, process.env.REACT_APP_PRODUCTION_URL);
  console.log(2222, process.env.NODE_ENV);
  return (
    <>
      <Router>
        <CustomLayout>
          <Routes>
            <Route exact path="/login" element={<LoginPage />} />
            <Route exact path="/register" element={<RegisterPage />} />
            <Route exact path="/dashboard" element={<StoreDashboard />} />
            <Route exact path="/store/menu" element={<ProtectedRoute Element={StoreMenu} />} />
            <Route exact path="/cart" element={<Cart />} />
            <Route
              exact
              path="/store/parking"
              element={<ProtectedRoute Element={StoreParking} />}
            />
            <Route exact path="/" element={<HomePage />} />
          </Routes>
        </CustomLayout>
      </Router>
    </>
  );
}

export default App;
