import { api } from "../configs/api";
import { AUTH_URL } from "./urls";

const register = ({ user_name, password, role }) => {
  return api().post(AUTH_URL + "/users", { user_name, password, role });
};

const login = ({ user_name, password }) => {
  return api().post(AUTH_URL + "/login", {
    user_name,
    password,
  });
};

const logout = () => {
  return api().post(AUTH_URL + "/logout");
};

const registerVehicle = (data) => {
  return api().post(AUTH_URL + "/vehicles/create", data);
};

const getProfile = () => {
  return api().get(AUTH_URL + "/get-user");
};

export default {
  register,
  login,
  logout,
  registerVehicle,
  getProfile,
};
