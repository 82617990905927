import { Table, Button } from "antd";
import { useState } from "react";
import styled from "styled-components";
import CreateDishModal from "../modals/modalCreateDish";

const CustomTable = ({ data = [], columns = [] }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Wrapper>
      <ToolBox>
        <TitleBox>
          <Title>Dishes</Title>
          <Description>This table help you to manage your store&apos;s dishes.</Description>
        </TitleBox>
        <Button onClick={showModal}>Create</Button>
        <CreateDishModal isModalOpen={isModalOpen} handleCancel={handleCancel} />
      </ToolBox>

      <Table rowKey={"_id"} columns={columns} dataSource={data} bordered />
    </Wrapper>
  );
};

const TitleBox = styled.div``;

const ToolBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Description = styled.div`
  font-size: 14px;
  color: lightgray;
  margin-bottom: 20px;
`;

const Title = styled.h1``;

const Wrapper = styled.div`
  padding: 10px;
`;

export default CustomTable;
