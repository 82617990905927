import { Button } from "antd";
import styled from "styled-components";

const ColumnSubMenu = ({ data }) => {
  const { title, listMenu, width } = data;
  return (
    <Wrapper width={width}>
      <Title>{title}</Title>
      {listMenu.map((menu) => {
        if (!menu.type) {
          return <Menu key={menu}>{menu}</Menu>;
        } else if (menu.type === "orange") {
          return <OrangeMenu key={menu.name}>{menu.name}</OrangeMenu>;
        } else if (menu.type === "button") {
          return (
            <ButtonWrapper key={menu.name}>
              <ButtonTitle>{menu.name}</ButtonTitle>
              <ButtonNote>{menu.note}</ButtonNote>
              <ButtonClick type="primary">Show now</ButtonClick>
            </ButtonWrapper>
          );
        } else if (menu.type === "image") {
          return <Image key={menu.name} src={menu.link} />;
        }
      })}
    </Wrapper>
  );
};

const Image = styled.img``;

const ButtonClick = styled(Button)`
  padding: 20px 15px;
  display: flex;
  align-items: center;
  color: black;
  margin-bottom: 40px;
`;

const ButtonNote = styled.div`
  color: #888;
  margin: 20px 0px;
`;

const ButtonTitle = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 20px;
  font-size: 18px !important;
  color: black;
`;

const ButtonWrapper = styled.div``;

const OrangeMenu = styled.div`
  margin-bottom: 10px;
  margin-top: 30px;
  cursor: pointer;
  color: #fab528;
`;

const Menu = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    color: #fab528;
  }
`;

const Title = styled.div`
  color: black;
  line-height: 13px;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  padding: 15px;
  width: ${(props) => (props.width ? props.width : "250px")};
  padding-top: 0px;
  border-right: 1px solid #e5e5e5;
`;

export default ColumnSubMenu;
