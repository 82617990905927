export const bestSellersList = {
  additionalTitle: [
    "All",
    "Fruits & Vegetables",
    "Frozen Seafoods",
    "Raw Meats",
    "Coffes & Teas",
    "Pet Foods",
    "Milks & Dairies",
  ],
  title: "Best Seller",
  numberOfDisplayItems: 6,
  list: [
    {
      src: "/images/01_8a-600x600.jpg",
      name: "Bar S – Classic Bun Lead",
      star: 4,
      price: 35.0,
      _id: "64771da208da86e5e94e65ee",
    },
    {
      src: "/images/01_27a-600x600.jpg",
      name: "Saute Pan Silver",
      star: 4.7,
      price: 1.9,
      _id: "64771f7c3bf07b5a2b4a13dd",
    },
    {
      src: "/images/02_1a.jpg",
      name: "Broccoli Crowns",
      star: 4.9,
      price: 10.0,
      _id: "64771fb13bf07b5a2b4a13e4",
    },
    {
      src: "/images/04_2a.jpg",
      name: "Häagen-Dazs Salted Caramel",
      star: 4.3,
      price: 11.57,
      _id: "4",
    },
    {
      src: "/images/04_3a.jpg",
      name: "Iceland 3 Solo Exotic Burst",
      star: 4.2,
      price: 11.57,
      _id: "6477200d3bf07b5a2b4a13f5",
    },
    {
      src: "/images/05_8a.jpg",
      name: "Slimming World Vegan Gre...",
      star: 4.5,
      price: 3.38,
      _id: "6",
    },
    {
      src: "/images/01_8a-600x600.jpg",
      name: "Bar S – Classic Bun Lead",
      star: 4,
      price: 35.0,
      _id: "64771da208da86e5e94e65ee",
    },
    {
      src: "/images/01_27a-600x600.jpg",
      name: "Saute Pan Silver",
      star: 4.7,
      price: 1.9,
      _id: "7",
    },
    {
      src: "/images/02_1a.jpg",
      name: "Broccoli Crowns",
      star: 4.9,
      price: 10.0,
      _id: "8",
    },
    {
      src: "/images/04_2a.jpg",
      name: "Häagen-Dazs Salted Caramel",
      star: 4.3,
      price: 11.57,
      _id: "9",
    },
    {
      src: "/images/04_3a.jpg",
      name: "Iceland 3 Solo Exotic Burst",
      star: 4.2,
      price: 11.57,
      _id: "10",
    },
    {
      src: "/images/05_8a.jpg",
      name: "Slimming World Vegan Gre...",
      star: 4.5,
      price: 3.38,
      _id: "11",
    },
  ],
};
