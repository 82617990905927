import { notification } from "antd";
import styled from "styled-components";

const AddToCartDes = styled.div`
  display: flex;
  color: gray;
`;

const Item = styled.div`
  color: #fab528;
  margin: 0px 5px;
`;

export const showNotification = ({
  onClick = () => {},
  message = "",
  type = "open", // success, error, info, warning, destroy
  quantity,
  icon,
  duration = 2,
}) => {
  return icon
    ? notification[type]({
        message,
        onClick,
        closeIcon: null,
        duration,
        description: (
          <AddToCartDes>
            Added <Item> {quantity} items</Item> to cart!
          </AddToCartDes>
        ),
        icon: <img height={20} width={20} src={icon} />,
      })
    : notification[type]({
        duration,
        message,
        onClick,
        closeIcon: null,
      });
};
