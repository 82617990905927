import {
  CREATE_DISH,
  CREATE_STORE,
  DELETE_DISH,
  GET_ALL_DISHES,
  GET_STORE_TOTAL_DATA,
  UPDATE_DISH,
} from "./types";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  getAllDishesAct,
  getAllDishesSuccessAct,
  getStoreTotalDataAct,
  getStoreTotalDataSuccessAct,
} from "./actions";
import { showNotification } from "../../utils/showNotification";
import { messageFromResponse } from "../../utils/messageFromRes";
import restaurantsService from "../../services/restaurants.service";

export function* createStore({ payload }) {
  try {
    const { name, phone, address, main_image, images, navigate } = payload;
    const result = yield call(restaurantsService.createStore, {
      name,
      phone,
      address,
      main_image,
      images,
    });
    showNotification({ type: "success", message: messageFromResponse(result) });
    navigate("/store/menu");
  } catch (err) {
    console.log({ saga: "createStore", err });
  }
}

export function* getAllDishes() {
  try {
    const result = yield call(restaurantsService.getAllDishes);
    yield put(getAllDishesSuccessAct(result));
  } catch (err) {
    console.log({ saga: "getAllDish", err });
  }
}

export function* createDish({ payload }) {
  try {
    const { name, price, main_image, images, status, tags } = payload;
    const { user } = yield select((state) => state.auth);
    const { store_id } = user || {};
    const result = yield call(restaurantsService.createDish, {
      name,
      price,
      main_image,
      images,
      store_id,
      status,
      tags
    });
    showNotification({ type: "success", message: messageFromResponse(result) });
    yield put(getAllDishesAct());
  } catch (err) {
    console.log({ saga: "createDish", err });
  }
}

export function* updateDish({ payload }) {
  try {
    const result = yield call(restaurantsService.updateDish, payload);
    showNotification({ type: "success", message: messageFromResponse(result) });
    yield put(getAllDishesAct());
  } catch (err) {
    console.log({ saga: "updateDish", err });
  }
}

export function* deleteDish({ payload }) {
  try {
    const { _id } = payload;
    const result = yield call(restaurantsService.deleteDish, {
      _id,
    });
    showNotification({ type: "success", message: messageFromResponse(result) });
    yield put(getStoreTotalDataAct());
  } catch (err) {
    console.log({ saga: "deleteDish", err });
  }
}

export function* getStoreAllData() {
  try {
    const result = yield call(restaurantsService.getStoreTotalData);
    yield put(getStoreTotalDataSuccessAct(result));
  } catch (err) {
    console.log({ saga: "getStoreAllData", err });
  }
}

export function* getAllDishesSaga() {
  yield takeLatest(GET_ALL_DISHES, getAllDishes);
}

export function* createStoreSaga() {
  yield takeLatest(CREATE_STORE, createStore);
}

export function* createDishSaga() {
  yield takeLatest(CREATE_DISH, createDish);
}

export function* updateDishSaga() {
  yield takeLatest(UPDATE_DISH, updateDish);
}

export function* getStoreAllDataSaga() {
  yield takeLatest(GET_STORE_TOTAL_DATA, getStoreAllData);
}

export function* deleteDishSaga() {
  yield takeLatest(DELETE_DISH, deleteDish);
}

export function* root() {
  yield all([
    createStoreSaga(),
    getStoreAllDataSaga(),
    deleteDishSaga(),
    createDishSaga(),
    updateDishSaga(),
    getAllDishesSaga(),
  ]);
}

export default root;
