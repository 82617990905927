import styled from "styled-components";
import BannerBlock from "../blocks/bannerBlock";
import CarouselButtonsBottom from "../carousels/rightBottom";
const bgUrlArray = [
  {
    url: "/images/banner-1.jpg",
    content: {
      title: (
        <>
          Active Summer With
          <br />
          Juice Milk 300ml
        </>
      ),
      description: (
        <>
          New arrivals with naturre fruits, juice
          <br />
          milks, essential for summer
        </>
      ),
      action: () => {},
    },
  },
  {
    url: "/images/banner-2.jpg",
    content: {
      title: (
        <>
          Active Summer With
          <br />
          Juice Milk 300ml
        </>
      ),
      description: (
        <>
          New arrivals with naturre fruits, juice
          <br />
          milks, essential for summer
        </>
      ),
      action: () => {},
    },
  },
];

const HomePageBanner = () => {
  return (
    <Wrapper style={{ backgroundImage: `url('/images/ezgif.com-webp-to-jpg (6).jpg')` }}>
      <Container>
        <CarouselButtonsBottom bgUrlArray={bgUrlArray} />
        <BannerBlock
          bgUrl="/images/ezgif.com-webp-to-jpg.jpg"
          styles={{
            backgroundColor: "#fac250",
            backgroundPosition: "bottom right",
          }}
          content={{
            title: (
              <>
                Active Summer With
                <br />
                Juice Milk 300ml
              </>
            ),
            description: (
              <>
                New arrivals with naturre fruits, juice
                <br />
                milks, essential for summer
              </>
            ),
            action: () => {},
          }}
        />
      </Container>
    </Wrapper>
  );
};

const Container = styled.div`
  display: grid;
  margin: 0px 55px;
  grid-template-columns: 67% 33%;
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  padding: 45px 15px;
`;

export default HomePageBanner;
