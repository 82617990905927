import styled, { keyframes } from "styled-components";
import { Button, Rate, InputNumber } from "antd";
import { BiExpand } from "react-icons/bi";
import { AiOutlineHeart, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { ImLoop2 } from "react-icons/im";
import { useState } from "react";
import useLocalStorage from "../../../customHooks/useLocalStorage";
import { showNotification } from "../../../utils/showNotification";

const ProductBlock = ({ data }) => {
  const { src, name, star, price, _id, main_image } = data;
  const [value, setValue] = useState(1);
  const [values, setValues] = useLocalStorage("cart", []);

  const addToCart = () => {
    setValues([
      ...values,
      {
        _id,
        quantity: value,
      },
    ]);
    showNotification({
      icon: main_image || src,
      message: <h6>{name}</h6>,
      quantity: value,
    });
  };
  return (
    <Wrapper>
      <StyledBiExpand />
      <StyledAiOutlineHeart />
      <StyledImLoop2 />
      <ImageBox>
        <Image src={main_image || src} />
      </ImageBox>
      <Title>{name}</Title>
      <Rate
        style={{ fontSize: "12px", marginBottom: "15px" }}
        disabled
        allowHalf
        defaultValue={star || 4.5}
      />
      <Note>3 Per Pack</Note>
      <Price>${price.toFixed(2)}</Price>
      <TotalBox>
        <Total>Total:</Total>
        <Price fontSize={"13px"}>${(value * price).toFixed(2)}</Price>
      </TotalBox>
      <InputBox>
        <StyledInputNumber
          controls={false}
          disabled
          addonBefore={
            <StyledAiOutlineMinus
              onClick={() => setValue((preValue) => (preValue > 0 ? preValue - 1 : preValue))}
            />
          }
          addonAfter={
            <StyledAiOutlinePlus
              onClick={() => setValue((preValue) => (preValue < 9 ? preValue + 1 : preValue))}
            />
          }
          value={value}
        />
      </InputBox>

      <StyledButton onClick={addToCart} type="primary">
        Add to Cart
      </StyledButton>
    </Wrapper>
  );
};

const Total = styled.div`
  margin: 0 5px 0 0;
  color: #888;
  font-size: 13px;
`;

const TotalBox = styled.div`
  display: flex;
  position: absolute;
  top: 275px;
  right: 25px;
  z-index: -1;
  opacity: 0;
`;

const StyledAiOutlinePlus = styled(AiOutlinePlus)`
  color: #666;
`;

const StyledAiOutlineMinus = styled(AiOutlineMinus)`
  color: #666;
`;

const InputBox = styled.div`
  position: absolute;
  top: 302px;
  right: 25px;
  z-index: -1;
  opacity: 0;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 105px;
  height: 25px;
  input {
    font-size: 13px !important;
    margin-top: 1px !important;
    height: 20px !important;
    transition: unset !important;
  }
  input:disabled {
    background-color: white;
  }
  div {
    div {
      background-color: white !important;
      border-radius: 0px !important;
    }
  }
`;

const StyledButton = styled(Button)`
  border-radius: 0px;
  width: 100%;
  z-index: -1;
  opacity: 0;
`;

const StyledImLoop2 = styled(ImLoop2)`
  color: #fab528;
  position: absolute;
  top: 72px;
  right: 20px;
  font-size: 15px;
  z-index: -1;
  opacity: 0;
`;

const StyledAiOutlineHeart = styled(AiOutlineHeart)`
  color: #fab528;
  position: absolute;
  top: 40px;
  right: 19px;
  z-index: -1;
  font-size: 18px;
  opacity: 0;
`;

const StyledBiExpand = styled(BiExpand)`
  color: #fab528;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: -1;
  font-size: 17px;
  opacity: 0;
`;

const Price = styled.div`
  color: #690;
  font-weight: 700;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "15px")};
  margin-bottom: 15px;
`;

const Note = styled.div`
  font-size: 11px;
  color: #666;
  display: block;
  line-height: 1;
  margin-bottom: 13px;
`;

const Title = styled.div`
  color: #09f;
  transition: ease 0.3s;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  &:hover {
    color: #fab528;
  }
`;

const ImageBox = styled.div`
  margin: 10px 0px 20px;
  height: 206px;
`;

const Image = styled.img`
  width: 100%;
`;

const turnLeft = keyframes`
  from {
    translate: 15px 0px;
    opacity: 0;
  }

  to {
    translate: 0px 0px;
    opacity: 1;
  }
`;

const turnUp = keyframes`
from {
  translate: 0px 20px;
  opacity: 0;
}

to {
  translate: 0px 0px;
  opacity: 1;
}
`;

const Wrapper = styled.div`
  position: relative;
  padding: 0px 25px 30px;
  min-width: 260px;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 2px 11px 0 rgb(0 0 0 / 14%);
    ${StyledButton} {
      z-index: 1;
      animation: ${turnUp} 0.6s linear;
      opacity: 1;
    }
    ${InputBox} {
      z-index: 1;
      animation: ${turnLeft} 0.6s linear;
      opacity: 1;
    }
    ${TotalBox} {
      z-index: 1;
      animation: ${turnLeft} 0.5s linear;
      opacity: 1;
    }

    ${StyledBiExpand} {
      z-index: 1;
      animation: ${turnLeft} 0.2s linear;
      opacity: 1;
    }
    ${StyledAiOutlineHeart} {
      z-index: 1;
      animation: ${turnLeft} 0.3s linear;
      opacity: 1;
    }
    ${StyledImLoop2} {
      z-index: 1;
      animation: ${turnLeft} 0.4s linear;
      opacity: 1;
    }
  }
  margin-left: 5px;
  cursor: pointer;
  background-color: white;
`;

export default ProductBlock;
