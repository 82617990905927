import { Badge, Rate, Table } from "antd";
import styled from "styled-components";
import CartBtn from "../../components/buttons/CartBtn";
import { BsArrowLeft } from "react-icons/bs";
import { HiOutlineHome } from "react-icons/hi";
import { RxReload } from "react-icons/rx";
import UsingCouon from "./UsingCoupon";
import Total from "./Total";
import { useEffect, useState } from "react";
import useLocalStorage from "../../customHooks/useLocalStorage";
import restaurantsService from "../../services/restaurants.service";
import { RxTrash } from "react-icons/rx";
import { calculateTotalCart } from "../../utils/calculateTotalCart";

const Cart = () => {
  const [productsList] = useLocalStorage("cart", []);
  const [data, setData] = useState([]);
  useEffect(() => {
    restaurantsService.getCartProducts(productsList).then(({ data }) => {
      setData(data?.data);
    });
  }, []);

  const expandedRowRender = ({ products }) => {
    const columns = [
      { title: "Product Name", dataIndex: "name", key: "name" },
      { title: "Quantity", dataIndex: "quantity", key: "quantity" },
      {
        title: "Unit Price",
        dataIndex: "price",
        key: "price",
        render: (text) => <Text>${Number(text).toFixed(2)}</Text>,
      },
      {
        title: "Star",
        dataIndex: "star",
        key: "star",
        render: () => (
          <FlexBox>
            <Rate style={{ fontSize: "12px" }} disabled allowHalf defaultValue={4.7} />
          </FlexBox>
        ),
      },
      {
        title: "Image",
        dataIndex: "main_image",
        key: "main_image",
        render: (text) => <Image src={text} />,
      },
      {
        title: "Action",
        dataIndex: "operation",
        key: "operation",
        render: () => <StyledRxTrash />,
      },
    ];

    return <Table columns={columns} dataSource={products} pagination={false} />;
  };
  const columns = [
    {
      title: "",
      dataIndex: "main_image",
      key: "main_image",
      width: 50,
      render: (text) => <Image src={text} />,
    },
    {
      title: "Store Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => <Text>{record.name}</Text>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: () => <StyledBadge count="take-away" />,
    },
    { title: "Address", dataIndex: "address", key: "address", render: () => <div>Ha Noi</div> },
    { title: "Phone", dataIndex: "phone", key: "phone", render: () => <div>123</div> },
    {
      title: "Action",
      key: "operation",
      render: () => <StyledRxTrash />,
    },
  ];

  // for (let i = 0; i < 3; ++i) {
  //   data.push({
  //     key: i.toString(),
  //     name: "Screen",
  //     platform: "iOS",
  //     version: "10.3.4.5654",
  //     upgradeNum: 500,
  //     creator: "Jack",
  //     createdAt: "2014-12-24 23:12:00",
  //   });
  // }
  return (
    <Wrapper>
      <HeaderTitle>Cart</HeaderTitle>

      <Table
        columns={columns}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ["0"],
          rowExpandable: (record) => record?.products.length !== 0,
        }}
        dataSource={data}
        pagination={{
          position: ["none", "none"],
        }}
      />
      <BtnBox>
        <FlexBox>
          <CartBtn Icon={BsArrowLeft} name={"Continue Shopping"} />
          <CartBtn Icon={HiOutlineHome} name={"Back to Home"} />
        </FlexBox>

        <CartBtn Icon={RxReload} name={"Update cart"} />
      </BtnBox>
      <CouponBox>
        <UsingCouon />
        <Total total={calculateTotalCart(data)} />
      </CouponBox>
    </Wrapper>
  );
};

const StyledRxTrash = styled(RxTrash)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  &:hover {
    color: red;
  }
`;

const StyledBadge = styled(Badge)`
  margin-left: 10px;
`;

const Text = styled.div`
  font-weight: 500;
`;

const Image = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-right: 5px;
`;

const CouponBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

const BtnBox = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  padding-bottom: 50px;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 40px;
`;

const HeaderTitle = styled.h1`
  text-align: center;
  font-size: 50px;
  margin: 90px 0px 80px 0px;
`;

const Wrapper = styled.div`
  margin: 0px auto 80px;
  width: 70%;
`;

export default Cart;
