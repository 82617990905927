import styled from "styled-components";

const CategoryBlock = ({ content, src }) => {
  return (
    <Wrapper>
      <Image src={src} />
      <Content>{content}</Content>
    </Wrapper>
  );
};

const Content = styled.div`
  font-size: 14px;
  line-height: 1.71429;
  text-align: center;
  font-weight: 500;
`;

const Image = styled.img`
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  width: 180px;
  background-color: #f7f7f6;
  border-radius: 10px;
  margin: 0 calc(30px / 2);
  padding: 46px 27px 38px 27px;
  transition: 0.5s;
  cursor: pointer;
  text-align: center;
  &:hover {
    background-color: white;
    box-shadow: 0 30px 40px 0 rgb(131 132 135 / 15%);
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default CategoryBlock;
