import { Menu } from "antd";
import styled from "styled-components";
import { AiOutlineRight } from "react-icons/ai";
import { useState } from "react";
import { get } from "lodash";
import ColumnSubMenu from "./ColumnSubMenu";
const { SubMenu } = Menu;

const CategoriesMenu = ({ data }) => {
  const [activeMenu, setActiveMenu] = useState("");
  return (
    <StyledMenu
      onOpenChange={(openKeys) => setActiveMenu(get(openKeys, [0], ""))}
      expandIcon={<></>}
      style={{ width: 256 }}
      mode="vertical">
      {data.map((category) =>
        category.type === "menu" ? (
          <StyledSubMenu
            key={category.label}
            title={
              <span>
                <span data-testid={category.label}>{category.label}</span>
              </span>
            }
          />
        ) : (
          <StyledSubMenu
            popupClassName="header-category-submenu"
            popupOffset={category.popupOffset}
            key={category.label}
            title={
              <MenuLine>
                <Label active={activeMenu === category.label}>{category.label}</Label>
                <StyledAiOutlineRight active={activeMenu === category.label} />
              </MenuLine>
            }>
            <SubMenusWrapper backgroundImage={category.backgroundImg}>
              {category.subMenus?.map((submenu) => (
                <ColumnSubMenu key={submenu.title} data={submenu} />
              ))}
            </SubMenusWrapper>
          </StyledSubMenu>
        ),
      )}
    </StyledMenu>
  );
};

const SubMenusWrapper = styled.div`
  display: flex;
  padding: 15px;
  background-image: ${(props) =>
    props.backgroundImage ? `url('${props.backgroundImage}')` : "unset"};
  div:last-child {
    border: none;
  }
`;

const Label = styled.span`
  color: ${(props) => (props.active ? "#fab528" : "black")};
`;

const StyledAiOutlineRight = styled(AiOutlineRight)`
  font-size: 12px;
  color: lightgray;
  transition: all 0.5s;
  transform: ${(props) => (props.active ? "rotate(180deg)" : "rotate(0deg)")};
`;

const MenuLine = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover > ${StyledAiOutlineRight} {
    transform: rotate(180deg);
    transition: all 0.5s;
  }
`;

const StyledSubMenu = styled(SubMenu)``;

const StyledMenu = styled(Menu)`
  border-radius: 0 !important;
  padding: 15px 0px 15px 25px !important;
  li div {
    font-size: 14px;
    margin-right: 25px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    border-bottom: 1px solid #e5e5e5;
    &:hover {
      background-color: white !important;
      color: #fab528 !important;
    }
  }

  li:last-child div {
    border-bottom: none;
  }
`;

export default CategoriesMenu;
