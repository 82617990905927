import { api } from "../configs/api";
import { PARKING_URL } from "./urls";

const findByUser = () => {
  return api().get(PARKING_URL + "/parking-lots/find-by-user");
};

const registerParkingLot = (data) => {
  return api().post(PARKING_URL + "/parking-lots/register", data);
};

export default {
  findByUser,
  registerParkingLot,
};
