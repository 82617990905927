import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import BannerBlock from "../../blocks/bannerBlock";

const CarouselButtonsBottom = ({ bgUrlArray = [] }) => {
  return (
    <Wrapper>
      <Carousel
        showArrows={false}
        showIndicators={false}
        renderArrowNext={(handleNext) => (
          <StyledButton right={"30px"} onClick={handleNext}>
            <RightOutlined />
          </StyledButton>
        )}
        renderArrowPrev={(handlePre) => (
          <StyledButton right={"60px"} onClick={handlePre}>
            <LeftOutlined />
          </StyledButton>
        )}
        showStatus={false}
        showThumbs={false}>
        {bgUrlArray.map(({ url, content }) => (
          <BannerBlock content={content} key={url} bgUrl={url} />
        ))}
      </Carousel>
    </Wrapper>
  );
};

const StyledButton = styled.div`
  position: absolute;
  bottom: 10px;
  right: ${(props) => props.right};
  height: 26px;
  width: 26px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  opacity: 0.6;
  &:hover {
    background-color: #fab528;
  }
  transition: all 0.5s;
`;

const Wrapper = styled.div`
  position: relative;
`;

export default CarouselButtonsBottom;
