import styled from "styled-components";

const HealthBox = ({ data, index }) => {
  const { src, header, title, content } = data;
  return (
    <Wrapper border={index / 2 === 0 ? "true" : "false"}>
      <Image src={src} />
      <EntryHeader>{header}</EntryHeader>
      <EntryTitle>{title}</EntryTitle>
      <EntryContent>{content}</EntryContent>
    </Wrapper>
  );
};
const EntryContent = styled.div`
  color: #666;
  font-family: "Muli", sans-serif;
  font-size: 14px;
  line-height: 1.71429;
  word-wrap: break-word;
`;

const EntryTitle = styled.div`
  cursor: pointer;
  margin: 0 0 19px;
  &:hover {
    color: #fab528;
  }
  font-size: 21px;
  font-weight: 600;
  color: #222;
  line-height: 1.25714;
  margin-bottom: 23px;
`;

const EntryHeader = styled.div`
  font-size: 13px;
  margin-top: 10px;
`;

const Image = styled.img`
  border-radius: 10px;
  margin-bottom: 29px;
  width: 100%;
`;

const Wrapper = styled.div`
  padding: 0px 25px 0px 25px;
  margin: 25px 0px 38px;
  border-right: ${(props) => (props.border === "true" ? "1px solid #eeeeee" : "unset")};
  width: 545px;
`;

export default HealthBox;
