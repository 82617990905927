// import OwnerLeftSideBar from "../components/sideBar/ownerLeftSideBar";
import styled from "styled-components";
import { ConfigProvider, Layout, Menu } from "antd";
import { useState } from "react";
import { CgCircleci } from "react-icons/cg";
import { FiLogOut } from "react-icons/fi";
import { sideBarConfigs } from "../../configs/layouts/storeOwner/sideBarConfigs";
import { useLocation, useNavigate } from "react-router-dom";

const { Sider } = Layout;

const renderItem = ({ type, Icon, title, subItems, endItem, key, url }, collapsed, navigate) => {
  switch (type) {
    case "sub-item":
      return (
        <StyledSubMenu key={key} icon={<Icon />} title={title}>
          {subItems.map(({ Icon, title, url }, index) => (
            <StyledMenuItem onClick={() => navigate(url)} key={title + index} icon={<Icon />}>
              <ItemBox>
                <ItemContent>{title}</ItemContent>
              </ItemBox>
            </StyledMenuItem>
          ))}
        </StyledSubMenu>
      );
    case "disabled-item":
      return (
        <StyledMenuItem padding={`${collapsed}`} key={key} disabled>
          <ItemBox>
            <ItemContent center={`${collapsed}`}>{title}</ItemContent>
          </ItemBox>
        </StyledMenuItem>
      );
    default:
      return (
        <StyledMenuItem onClick={() => navigate(url)} key={key} icon={<Icon />}>
          <ItemBox>
            <ItemContent>{title}</ItemContent>
            {!collapsed && endItem}
          </ItemBox>
        </StyledMenuItem>
      );
  }
};

const StoreOwnerLayout = ({ children }) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#9a55ff",
        },
      }}>
      <Layout
        style={{
          minHeight: "100vh",
        }}>
        <StyledSider
          width={250}
          theme="light"
          collapsible
          collapsed={collapsed}
          collapsedWidth={115}
          trigger={<StyledCollapsedIcon collapsed={`${collapsed}`} />}
          onCollapse={(value) => setCollapsed(value)}>
          <SiderContent>
            <SideHeadBox justifyEnd={`${collapsed}`}>
              <StyledMdCatchingPokemon />
              {!collapsed && <SideHeadText>Luma.</SideHeadText>}
            </SideHeadBox>
            <StyledMenu
              selectedKeys={sideBarConfigs
                .filter((item) => item.url === location.pathname)
                .map((item) => item.key)}>
              {sideBarConfigs.map((item) => renderItem(item, collapsed, navigate))}
            </StyledMenu>
          </SiderContent>
        </StyledSider>
        <Layout
          style={{ backgroundColor: "white", paddingLeft: "20px", paddingRight: "20px" }}
          className="site-layout">
          {children}
        </Layout>
      </Layout>

      {/* <Wrapper>
        <OwnerLeftSideBar />
      </Wrapper> */}
    </ConfigProvider>
  );
};

const StyledCollapsedIcon = styled(FiLogOut)`
  transform: ${(props) => (props.collapsed === "true" ? "unset" : "rotate(180deg)")};
  font-size: 20px;
  color: #a6a6a6;
  margin-bottom: 40px;
`;

const StyledSubMenu = styled(Menu.SubMenu)``;

const StyledMenuItem = styled(Menu.Item)`
  padding-inline: ${(props) => (props.padding === "true" ? "0 !important" : "inherit")};
  span.ant-menu-title-content {
    display: flex;
    width: 100%;
  }
  svg.ant-menu-item-icon {
    min-width: 20px !important;
  }
  display: flex !important;
  align-items: center;
`;

const ItemContent = styled.div`
  text-align: ${(props) => (props.center === "true" ? "center !important" : "inherit")};
  width: 100%;
`;

const ItemBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const SiderContent = styled.div`
  padding: 0px 15px;
`;

const StyledMenu = styled(Menu)`
  border-inline-end: none !important;
`;

const StyledSider = styled(Sider)`
  border-right: 1px solid #f0f0f0;
  li {
    color: #a6a6a6;
    font-size: 14px;
    font-weight: 500;
    svg {
      font-size: 20px !important;
    }
  }

  li.ant-menu-item-disabled {
    color: black !important;
  }
`;

const StyledMdCatchingPokemon = styled(CgCircleci)`
  font-size: 30px;
  color: #9a55ff;
  background-color: white;
  border-radius: 50%;
  border: none;
`;

const SideHeadText = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #9a55ff;
  margin-left: 10px;
`;

const SideHeadBox = styled.div`
  justify-content: ${(props) => (props.justifyEnd === "true" ? "flex-end" : "unset")};
  display: flex;
  align-items: center;
  height: 32px;
  overflow: hidden;
  margin: 40px 25px 30px 17px;
`;

export default StoreOwnerLayout;
