import { Avatar, Dropdown, Divider } from "antd";
import styled from "styled-components";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import IconHaveBadge from "../Share/IconHaveBadge";
import { useNavigate } from "react-router-dom";
import CreateStoreModal from "../modals/modalCreateStore";
import RegisterVehicleModal from "../modals/registerVehicle";
import { useState } from "react";
import { logoutAct } from "../../redux/auth/actions";
import { useCustomCookie } from "../../customHooks/useCustomCookie";

const UserFeatures = () => {
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const { user_name, store_id, vehicle_id } = user || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , removeAuthCookies] = useCustomCookie("Authentication");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vehicleModalOpen, setVehicleModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navigateDashboard = () => {
    navigate("/dashboard");
  };

  const logout = () => {
    removeAuthCookies();
    dispatch(logoutAct());
  };

  const redirectStoreMenu = () => navigate("/store/menu");

  const items = [
    {
      key: "5",
      label: <div onClick={() => navigate("/login")}>Sign In</div>,
    },
    {
      key: "6",
      label: <div onClick={() => navigate("/register")}>Sign Up</div>,
    },
  ];

  const loggedInItems = [
    {
      key: 1,
      label: (
        <>
          <a target="_blank">{user_name}</a>
          <Divider
            style={{
              margin: "5px 0px 0px 0px",
              paddingLeft: "-10px",
              paddingRight: "-10px",
            }}></Divider>
        </>
      ),
      disabled: true,
    },
    {
      key: 2,
      label: <div>Personal Information</div>,
    },
    {
      key: 3,
      label: <div onClick={navigateDashboard}>Dashboard</div>,
    },
    {
      key: 4,
      label: store_id ? (
        <div onClick={redirectStoreMenu}>My Store</div>
      ) : (
        <div onClick={showModal}>Create Store</div>
      ),
    },
    {
      key: 6,
      danger: true,
      label: <div onClick={logout}>Logout</div>,
    },
  ];

  return (
    <>
      {isLoggedIn ? (
        <Dropdown
          menu={{
            items: vehicle_id
              ? loggedInItems
              : [
                  ...loggedInItems,
                  {
                    key: 5,
                    label: (
                      <div onClick={() => setVehicleModalOpen((isOpen) => !isOpen)}>
                        Register Vehicle
                      </div>
                    ),
                  },
                ].sort((a, b) => a.key - b.key),
          }}
          arrow>
          <StyledAvatar size={40} icon={<UserOutlined />} />
        </Dropdown>
      ) : (
        <Dropdown placement="bottomRight" menu={{ items }} arrow>
          <IconWrap>
            <IconHaveBadge
              badge={false}
              Icon={UserOutlined}
              style={{ fontSize: "25px", marginLeft: "30px" }}
            />
          </IconWrap>
        </Dropdown>
      )}
      <CreateStoreModal isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} />
      <RegisterVehicleModal
        isModalOpen={vehicleModalOpen}
        handleCancel={() => setVehicleModalOpen((isOpen) => !isOpen)}
        handleOk={() => setVehicleModalOpen((isOpen) => !isOpen)}
      />
    </>
  );
};

const IconWrap = styled.div`
  cursor: pointer;
`;

const StyledAvatar = styled(Avatar)`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-6px);
  cursor: pointer;
`;

export default UserFeatures;
