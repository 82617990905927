import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import createReducer from "./syntheticReducer";
import rootSaga from "./syntheticSaga";

const dev = process.env.NODE_ENV === "development";
const _initialState = {};

export const initStore = (initialState = _initialState) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];
  const enhancers = [applyMiddleware(...middlewares)];
  let store = createStore(createReducer(), initialState, ...enhancers);

  if (dev) {
    store = createStore(createReducer(), initialState, composeWithDevTools(...enhancers));
  }

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};
