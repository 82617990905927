import styled from "styled-components";

const DotBlock = ({ opacity = 1 }) => {
  return (
    <Wrapper opacity={opacity}>
      <Content />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background-color: #7e4ace;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => props.opacity};
`;

const Content = styled.div`
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: white;
`;

export default DotBlock;
