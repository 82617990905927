import IconHaveBadge from "../Share/IconHaveBadge";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { RxTrash } from "react-icons/rx";
import { useNavigate } from "react-router";
import styled from "styled-components";

const ShoppingCart = ({ products = [], total, count }) => {
  const navigate = useNavigate();
  const finalItem = {
    key: "final",
    label: (
      <FinalBox>
        <FlexBox margin="10px" align="center">
          <Text weight={500}>Total :</Text>
          <TotalPrice>${total}</TotalPrice>
        </FlexBox>
        <FlexBox>
          <StyledBtn onClick={() => navigate("/cart")} width="140px" color="black" bgColor="#eee">
            View Cart
          </StyledBtn>
          <StyledBtn onClick={() => navigate("/cart")} width="140px" color="#fff" bgColor="#ff7200">
            Checkout
          </StyledBtn>
        </FlexBox>
      </FinalBox>
    ),
  };
  const loggedInItems = products.map((product, index) => ({
    key: product._id,
    label: (
      <FlexBox border={index !== 0} padding={"20px"} style={{ width: "350px" }}>
        <FlexBox center="unset">
          <Image src={product.main_image} />
          <div>
            <Text>{product.name}</Text>
            <FlexBox center="unset">
              ${Number(product.price).toFixed(2)} <GrayText>(x{product.quantity})</GrayText>
            </FlexBox>
          </div>
        </FlexBox>
        <StyledRxTrash />
      </FlexBox>
    ),
    disabled: true,
  }));
  return (
    <Dropdown
      disabled={loggedInItems.length === 0}
      overlayStyle={
        {
          // padding: "30px",
        }
      }
      arrow={{
        pointAtCenter: false,
      }}
      // placement="bottom"
      menu={{ items: loggedInItems.length > 0 ? [...loggedInItems, finalItem] : [] }}>
      <div>
        <IconHaveBadge
          badge={true}
          count={count}
          Icon={ShoppingCartOutlined}
          style={{ fontSize: "25px", marginLeft: "30px", color: "black", cursor: "pointer" }}
        />
      </div>
    </Dropdown>
  );
};

const StyledBtn = styled(Button)`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  width: ${({ width }) => width};
  font-weight: bold;
  border-color: ${({ bgColor }) => bgColor};
  height: 40px;
  &:hover {
    color: ${({ color }) => color} !important;
    border-color: lightgray !important;
  }
`;

const FinalBox = styled.div`
  padding: 20px;
  padding-top: 0px;
`;
const TotalPrice = styled.div`
  color: #fab528;
  font-size: 16px;
`;

const GrayText = styled.div`
  color: gray;
  margin-left: 5px;
`;
const StyledRxTrash = styled(RxTrash)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-top: 5px;
  &:hover {
    color: red;
  }
`;

const Text = styled.div`
  line-height: 1.428;
  color: #000;
  font-family: "Muli", sans-serif;
  font-size: 14px;
  font-weight: ${(props) => (props.weight ? props.weight : 300)};
`;

const Image = styled.img`
  height: 30px;
  width: 30px;
  margin-right: 10px;
  border-radius: 5px;
`;

const FlexBox = styled.div`
  margin-bottom: ${({ margin }) => margin};
  display: flex;
  justify-content: ${(props) => (props.center ? props.center : "space-between")};
  line-height: 1.428;
  align-items: ${({ align }) => align};
  color: #000;
  font-family: "Muli", sans-serif;
  font-size: 13px;
  padding: ${(props) => props.padding};
  border-top: ${(props) => (props.border ? "1px solid rgb(243 243 243)" : "unset")};
`;

export default ShoppingCart;
