import { Form, Select } from "antd";
import styled from "styled-components";

const SelectMultipleBox = ({ label, required, name, rules, options, handleChange, width }) => {
  return (
    <StyledForm.Item rules={rules} label={label} required={required} name={name}>
      <Select
        allowClear
        mode="multiple"
        style={{ width }}
        onChange={handleChange}
        options={options}
      />
    </StyledForm.Item>
  );
};

const StyledForm = styled(Form)``;

export default SelectMultipleBox;
