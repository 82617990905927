import StoreBlock from "../../components/StoreBlock";
import styled from "styled-components";
import HomePageBanner from "../../components/banners/HomePageBanner";
import CarouselButtonsTop from "../../components/carousels/topRight";
import CategoryBlock from "../../components/blocks/categoryBlock";
import ProductBlock from "../../components/blocks/productBlock";
import { categoriesList } from "../../configs/carousels/categoriesList";
import { featuredBrandsList } from "../../configs/carousels/featuredBrandsList";
import { bestSellersList } from "../../configs/carousels/bestSellersList";
import HomePagebrand from "../../components/blocks/homePageBrand";
import { esentialProductList } from "../../configs/carousels/esentialProductList";
import { justLandingList } from "../../configs/carousels/justLandingList";
import HealthDaily from "../../components/healthDaily";
import { useEffect } from "react";
import restaurantsService from "../../services/restaurants.service";
import { productTags } from "../../configs/constants";
import { useState } from "react";

const HomePage = () => {
  const [bestSeller, setBestSeller] = useState([]);
  const [justLanding, setJustLanding] = useState([]);
  const [essential, setEssential] = useState([]);

  useEffect(() => {
    restaurantsService
      .getDishesBytag(productTags.BEST_SELLER)
      .then(({ data }) => setBestSeller(data?.data));
    restaurantsService
      .getDishesBytag(productTags.ESSENTIAL)
      .then(({ data }) => setEssential(data?.data));
    restaurantsService
      .getDishesBytag(productTags.JUST_LANDING)
      .then(({ data }) => setJustLanding(data?.data));
  }, []);
  return (
    <Wrapper>
      <HomePageBanner />
      {categoriesList && (
        <PaddingBox>
          <CarouselButtonsTop
            additionalTitle={categoriesList.additionalTitle}
            title={categoriesList.title}
            numberOfDisplayItems={categoriesList.numberOfDisplayItems}>
            {categoriesList.list.map(({ src, content }, index) => (
              <CategoryBlock key={`${index}-${content}`} src={src} content={content} />
            ))}
          </CarouselButtonsTop>
        </PaddingBox>
      )}

      {featuredBrandsList && (
        <PaddingBox>
          <CarouselButtonsTop
            additionalTitle={featuredBrandsList.additionalTitle}
            title={featuredBrandsList.title}
            numberOfDisplayItems={featuredBrandsList.numberOfDisplayItems}>
            {featuredBrandsList.list.map((featuredBrand, index) => (
              <StoreBlock key={`${index}-${featuredBrand.title}`} data={featuredBrand} />
            ))}
          </CarouselButtonsTop>
        </PaddingBox>
      )}

      <GrayContainer>
        {bestSellersList && (
          <PaddingBox>
            <CarouselButtonsTop
              arrowColor={"white"}
              additionalTitle={bestSellersList.additionalTitle}
              title={bestSellersList.title}
              numberOfDisplayItems={bestSellersList.numberOfDisplayItems}>
              {(bestSeller && bestSeller.length > 0 ? bestSeller : bestSellersList.list).map(
                (bestSeller, index) => (
                  <ProductBlock key={`${index}-${bestSeller.name}`} data={bestSeller} />
                ),
              )}
            </CarouselButtonsTop>
          </PaddingBox>
        )}
        {justLandingList && (
          <PaddingBox>
            <CarouselButtonsTop
              arrowColor={"white"}
              additionalTitle={justLandingList.additionalTitle}
              title={justLandingList.title}
              numberOfDisplayItems={justLandingList.numberOfDisplayItems}>
              {(justLanding && justLanding.length > 0 ? justLanding : justLandingList.list).map(
                (bestSeller, index) => (
                  <ProductBlock key={`${index}-${bestSeller.name}`} data={bestSeller} />
                ),
              )}
            </CarouselButtonsTop>
          </PaddingBox>
        )}
        <PaddingBox>
          <HomePagebrand />
        </PaddingBox>
        {esentialProductList && (
          <PaddingBox>
            <CarouselButtonsTop
              arrowColor={"white"}
              additionalTitle={esentialProductList.additionalTitle}
              title={esentialProductList.title}
              numberOfDisplayItems={esentialProductList.numberOfDisplayItems}>
              {(essential && essential.length > 0 ? essential : esentialProductList.list).map(
                (bestSeller, index) => (
                  <ProductBlock key={`${index}-${bestSeller.name}`} data={bestSeller} />
                ),
              )}
            </CarouselButtonsTop>
          </PaddingBox>
        )}
        <PaddingBox>
          <HealthDaily />
        </PaddingBox>
      </GrayContainer>
    </Wrapper>
  );
};

const GrayContainer = styled.div`
  background-image: url("/images/ezgif.com-webp-to-jpg (5).jpg");
  background-color: #f3f3f3;
  background-size: cover;
  background-repeat: no-repeat;
`;

const PaddingBox = styled.div`
  padding: 77px 66px 10px 66px;
`;

const Wrapper = styled.div`
  -moz-user-select: none !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
`;

export default HomePage;
