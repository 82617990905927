import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../components/CustomTable";
import { deleteDishAct, getStoreTotalDataAct } from "../../redux/restaurants/actions";
import { Image, Space } from "antd";

const columns = (deleteDishes) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Image",
    dataIndex: "main_image",
    render: (text) => <Image width={20} src={text} />,
    key: "main_image",
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <a onClick={console.log(record)}>Update</a>
        <a onClick={() => deleteDishes(record._id)}>Delete</a>
      </Space>
    ),
  },
];

const StoreDashboard = () => {
  const dispatch = useDispatch();
  const { dishes } = useSelector((state) => state.restaurants);
  useEffect(() => {
    dispatch(getStoreTotalDataAct());
  }, []);

  const deleteDishes = (_id) => {
    dispatch(deleteDishAct({ _id }));
  };
  return (
    <>
      <CustomTable data={dishes} columns={columns(deleteDishes)} />
    </>
  );
};

export default StoreDashboard;
