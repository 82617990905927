import styled from "styled-components";
import DotBlock from "../DotBlock";

const ParkingCarBlock = ({ title, number = 0, unit = "Slots", opacity }) => {
  return (
    <Wrapper>
      <DotBox>
        <DotBlock opacity={opacity} />
      </DotBox>
      <ContentBox>
        <Title>{title}</Title>
        <NumberBox>
          <Number>{number}</Number>
          <Unit>{unit}</Unit>
        </NumberBox>
      </ContentBox>
    </Wrapper>
  );
};
const Unit = styled.div`
  color: #bebebe;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-left: 5px;
`;
const Number = styled.div`
  font-size: 25px;
  font-weight: 600;
  line-height: 22px;
`;
const NumberBox = styled.div`
  display: flex;
  align-items: flex-end;
`;
const Title = styled.div`
  line-height: 21px;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 8px;
`;
const ContentBox = styled.div``;

const DotBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 10px;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 5px;
  border-right: 1px solid #f5f5f5;
`;

export default ParkingCarBlock;
