export const CREATE_STORE = "CREATE_STORE";
export const CREATE_STORE_SUCCESS = "CREATE_STORE_SUCCESS";
export const CREATE_STORE_FAIL = "CREATE_STORE_FAIL";

export const GET_STORE_TOTAL_DATA = "GET_STORE_TOTAL_DATA";
export const GET_STORE_TOTAL_DATA_SUCCESS = "GET_STORE_TOTAL_DATA_SUCCESS";

export const DELETE_DISH = "DELETE_DISH";
export const CREATE_DISH = "CREATE_DISH";
export const UPDATE_DISH = "UPDATE_DISH";
export const GET_ALL_DISHES = "GET_ALL_DISHES";
export const GET_ALL_DISHES_SUCCESS = "GET_ALL_DISHES_SUCCESS";
