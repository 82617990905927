export const featuredBrandsList = {
  additionalTitle: "All Offers",
  title: "Featured Brands",
  numberOfDisplayItems: 4,
  list: [
    {
      src: "/images/homepage-new-brand-img-1.png",
      title: "New Snach Release - Food Pourch",
      note: "Fast Food - Byriania",
      star: 5,
      heart: 5435,
      time: 60,
    },
    {
      src: "/images/homepage-new-brand-img-2.png",
      title: "Happy Tea 100% Organic - Itea Jsc",
      note: "Tea - Chris",
      star: 4.9,
      heart: 675,
      time: 60,
    },
    {
      src: "/images/homepage-new-brand-img-3.png",
      title: "Can Power - Soda Luu",
      note: "Drink - Tom",
      star: 4.7,
      heart: 145,
      time: 60,
    },
    {
      src: "/images/homepage-new-brand-img-4.png",
      title: "Fresh Meat Saugage - Farmat",
      note: "Meat - Farmat",
      star: 4.9,
      heart: 1345,
      time: 60,
    },
    {
      src: "/images/homepage-new-brand-img-1.png",
      title: "New Snach Release - Food Pourch",
      note: "Fast Food - Byriania",
      star: 5,
      heart: 5435,
      time: 60,
    },
    {
      src: "/images/homepage-new-brand-img-2.png",
      title: "Happy Tea 100% Organic - Itea Jsc",
      note: "Tea - Chris",
      star: 4.9,
      heart: 675,
      time: 60,
    },
    {
      src: "/images/homepage-new-brand-img-3.png",
      title: "Can Power - Soda Luu",
      note: "Drink - Tom",
      star: 4.7,
      heart: 145,
      time: 60,
    },
    {
      src: "/images/homepage-new-brand-img-4.png",
      title: "Fresh Meat Saugage - Farmat",
      note: "Meat - Farmat",
      star: 4.9,
      heart: 1345,
      time: 60,
    },
  ],
};
