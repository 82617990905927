import { Badge } from "antd";
import { UserOutlined } from "@ant-design/icons";

const IconHaveBadge = ({ Icon, style, badge, count = 2 }) => {
  return !badge ? (
    <>{Icon ? <Icon style={style} /> : <UserOutlined style={{ fontSize: "28px" }} />}</>
  ) : (
    <Badge style={{ color: "black" }} color={"#FAB526"} size="medium" count={count} showZero>
      {Icon ? <Icon style={style} /> : <UserOutlined style={{ fontSize: "28px" }} />}
    </Badge>
  );
};

export default IconHaveBadge;
