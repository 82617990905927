import { Form, Select } from "antd";
import styled from "styled-components";

const SelectBox = ({ label, required, name, rules, options, handleChange, width }) => {
  return (
    <StyledForm.Item rules={rules} label={label} required={required} name={name}>
      <Select style={{ width }} onChange={handleChange} options={options} />
    </StyledForm.Item>
  );
};

const StyledForm = styled(Form)``;

export default SelectBox;
