import Carousel from "react-multi-carousel";
import styled from "styled-components";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { BsChevronRight } from "react-icons/bs";

const CarouselButtonsTop = ({
  numberOfDisplayItems = 1,
  title,
  additionalTitle,
  children,
  arrowColor,
}) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: numberOfDisplayItems,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: numberOfDisplayItems,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const ButtonGroup = ({ next, previous }) => {
    return (
      <GroupButtons>
        <StyledButton color={arrowColor} right={"60px"} onClick={previous}>
          <LeftOutlined />
        </StyledButton>
        <StyledButton color={arrowColor} right={"30px"} onClick={next}>
          <RightOutlined />
        </StyledButton>
      </GroupButtons>
    );
  };

  return (
    <Wrapper>
      <HeaderBox>
        <TitleBox>
          <Title>{title}</Title>
          <AddtionalTitle>
            {Array.isArray(additionalTitle) ? (
              <>
                {additionalTitle.map((title) => (
                  <TextBox color="black" key={title}>
                    {title}
                  </TextBox>
                ))}
              </>
            ) : (
              <TextBox>
                {additionalTitle}
                <StyledBsChevronRight />
              </TextBox>
            )}
          </AddtionalTitle>
        </TitleBox>
      </HeaderBox>

      <Carousel
        responsive={responsive}
        removeArrowOnDeviceType={["tablet", "mobile", "desktop", "superLargeDesktop"]}
        arrows={false}
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}>
        {children}
      </Carousel>
    </Wrapper>
  );
};

const StyledBsChevronRight = styled(BsChevronRight)`
  margin-left: 5px;
`;

const TextBox = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => (props.color ? props.color : "#666")};
  transition: ease 0.3s;
  font-size: 14px;
  line-height: 1.71429;
  margin-left: 20px;
  cursor: pointer;
  &:hover {
    color: #fab528;
  }
`;

const GroupButtons = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 0px;
  margin-right: 10px;
`;

const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AddtionalTitle = styled.div`
  margin-left: 35px;
  display: flex;
`;

const Title = styled.h3`
  margin-bottom: 4px;
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 25px;
  padding-left: 10px;
`;

const StyledButton = styled.div`
  height: 26px;
  width: 26px;
  background-color: ${(props) => (props.color ? props.color : "#f3f3f3")};
  border-color: #02010100;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 100;
  &:hover {
    background-color: #fab528;
  }
  transition: all 0.5s;
  margin-right: 5px;
`;

const Wrapper = styled.div`
  position: relative;
`;

export default CarouselButtonsTop;
