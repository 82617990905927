import {
  LOGOUT,
  LOGIN,
  REGISTER,
  REGISTER_VEHICLE,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
} from "./types";
import { action } from "typesafe-actions";

export function loginAct({ user_name, password }, navigate, setCookie) {
  return action(LOGIN, { user_name, password, navigate, setCookie });
}

export function registerVehicleAct(data) {
  return action(REGISTER_VEHICLE, data);
}

export function registerAct(user_name, password, role, navigate) {
  return action(REGISTER, { user_name, password, role, navigate });
}

export function logoutAct() {
  return action(LOGOUT);
}

export function getProfileAct() {
  return action(GET_PROFILE);
}

export function getProfileSuccessAct(data) {
  return action(GET_PROFILE_SUCCESS, data);
}

export function getProfileFailAct() {
  return action(GET_PROFILE_FAIL);
}
