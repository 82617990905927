export const categoriesList = {
  additionalTitle: "All Categories",
  title: "Browse by Category",
  numberOfDisplayItems: 8,
  list: [
    {
      src: "/images/homepage-new-cat-2.png",
      content: "Breads Sweets",
    },
    {
      src: "/images/homepage-new-cat-2.png",
      content: "Breads Sweets",
    },
    {
      src: "/images/homepage-new-cat-2.png",
      content: "Breads Sweets",
    },
    {
      src: "/images/homepage-new-cat-2.png",
      content: "Breads Sweets",
    },
    {
      src: "/images/homepage-new-cat-2.png",
      content: "Breads Sweets",
    },
    {
      src: "/images/homepage-new-cat-2.png",
      content: "Breads Sweets",
    },
    {
      src: "/images/homepage-new-cat-2.png",
      content: "Breads Sweets",
    },
    {
      src: "/images/homepage-new-cat-2.png",
      content: "Breads Sweets",
    },
    {
      src: "/images/homepage-new-cat-2.png",
      content: "Breads Sweets",
    },
    {
      src: "/images/homepage-new-cat-2.png",
      content: "Breads Sweets",
    },
  ],
};
