import styled from "styled-components";

const Blue = styled.div`
  display: inline;
  color: #09f;
  cursor: pointer;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const Black = styled.div`
  display: inline;
  cursor: pointer;
  color: #222;
  font-weight: 600;
  &:hover {
    color: #fab528;
  }
`;

export const healthDailyList = [
  {
    src: "/images/b1.jpg",
    header: (
      <div>
        By <Blue>Furion</Blue> in <Black>Foods</Black> on September 30, 2019
      </div>
    ),
    title: "The Evolution of Food",
    content:
      "Lorem ipsum dolor sit amet, consect etur adipiscing elit. Aenean sagittis ornare diam, quis pharetrat...",
  },
  {
    src: "/images/b1.jpg",
    header: (
      <div>
        By <Blue>Furion</Blue> in <Black>Foods</Black> on September 30, 2019
      </div>
    ),
    title: "The Evolution of Food",
    content:
      "Lorem ipsum dolor sit amet, consect etur adipiscing elit. Aenean sagittis ornare diam, quis pharetrat...",
  },
];
