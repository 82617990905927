import { Form, Input } from "antd";

const NumberBox = ({ label, required, name, placeholder, prefix, suffix, rules }) => {
  return (
    <Form.Item rules={rules} label={label} required={required} name={name}>
      <Input suffix={suffix} prefix={prefix} type="number" placeholder={placeholder} />
    </Form.Item>
  );
};

export default NumberBox;
