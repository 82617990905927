import { combineReducers } from "redux";
import auth from "./auth/reducer";
import restaurants from "./restaurants/reducer";
import parking from "./parking/reducer";

export default function createReducer() {
  return combineReducers({
    auth,
    restaurants,
    parking,
  });
}
