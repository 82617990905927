export const AUTH_URL = `${
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : process.env.REACT_APP_PRODUCTION_URL
}/auth`;

export const RESTAURANT_URL = `${
  process.env.NODE_ENV === "development"
    ? "http://localhost:3005"
    : process.env.REACT_APP_PRODUCTION_URL
}/restaurants`;

export const PARKING_URL = `${
  process.env.NODE_ENV === "development"
    ? "http://localhost:3004"
    : process.env.REACT_APP_PRODUCTION_URL
}/parking`;
