import { Badge, Button } from "antd";
import styled from "styled-components";
import { BiPlus } from "react-icons/bi";

const TableHeader = ({ showDrawer, data = {} }) => {
  return (
    <Wrapper>
      <TitleBox>
        <TitleLine>
          <Title>{data.title}</Title>
          <Badge style={{ color: "" }} count="101 users" color={"#9A55ff"} />
        </TitleLine>
        <Note>{data.note}</Note>
      </TitleBox>
      {data?.buttons.map((button) => (
        <StyledButton type={"primary"} key={button.form} onClick={() => showDrawer(button.form)}>
          <StyledBiPlus />
          {button.title}
        </StyledButton>
      ))}
    </Wrapper>
  );
};

const StyledBiPlus = styled(BiPlus)`
  margin-right: 5px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-right: 10px;
`;

const Note = styled.div`
  color: #9d9d9d;
`;

const TitleLine = styled.div`
  display: flex;
  align-items: center;
`;

const TitleBox = styled.div``;

const Wrapper = styled.div`
  padding: 30px 0px 30px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default TableHeader;
