import styled from "styled-components";
import IconHaveBadge from "../Share/IconHaveBadge";
import { HeartOutlined } from "@ant-design/icons";
import UserFeatures from "./UserFeatures";
import ShoppingCart from "./ShoppingCart";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import restaurantsService from "../../services/restaurants.service";
import useLocalStorage from "../../customHooks/useLocalStorage";
import { calculateTotalCart } from "../../utils/calculateTotalCart";
import { calculateTotalProducts } from "../../utils/calculateTotalProducts";

const UserInfo = () => {
  const { budget } = useSelector((state) => state.auth);
  const [stores, setStores] = useState([]);
  const [productsList] = useLocalStorage("cart", []);
  useEffect(() => {
    restaurantsService.getCartProducts(productsList).then(({ data }) => {
      const stores = data?.data || [];
      setStores([...stores]);
    });
  }, [productsList]);
  return (
    <Wrapper>
      <PhoneBox>
        <Phone>+8439 452 4455</Phone>
        <Support>Support 24/7</Support>
      </PhoneBox>
      <InforBox>
        <UserFeatures />
        <IconHaveBadge
          badge={true}
          Icon={HeartOutlined}
          style={{ fontSize: "25px", marginLeft: "30px" }}
        />
        <ShoppingCart
          products={stores.reduce((products, store) => [...products, ...store.products], [])}
          total={calculateTotalCart(stores)}
          count={calculateTotalProducts(stores)}
        />
      </InforBox>
      <CartBox>
        <CartTitle>{budget ? "Your Budget" : "Your Cart"}</CartTitle>
        <CartMoney>${budget ? Number(budget).toFixed(2) : "0.00"}</CartMoney>
      </CartBox>
    </Wrapper>
  );
};

const CartMoney = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  display: block;
`;

const CartTitle = styled.div`
  line-height: 1;
  display: block;
  margin-bottom: 7px;
  font-size: 11px;
  color: #777;
`;

const CartBox = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const InforBox = styled.div`
  display: flex;
  margin-left: 50px;
`;

const Support = styled.div`
  color: #999;
  font-size: 13px;
  font-weight: 400;
`;

const Phone = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 5px;
`;

const PhoneBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
`;

const Wrapper = styled.div`
  font-family: "Mulish", sans-serif;
  display: flex;
  width: 40.5%;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  justify-content: flex-end;
`;

export default UserInfo;
