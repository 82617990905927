import styled from "styled-components";
import Content from "./Content";

const BannerBlock = ({ bgUrl, content, styles = {} }) => {
  const { title, description, action } = content;
  return (
    <Wrapper style={{ backgroundImage: `url(${bgUrl})`, ...styles }}>
      <Content title={title} description={description} action={action} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-repeat: no-repeat;
  /* background-size: cover;
  object-fit: cover; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 460px;
  border-radius: 10px;
  width: 100%;
`;

export default BannerBlock;
