import { RxDashboard } from "react-icons/rx";
import { HiOutlineUserGroup, HiOutlineDocumentSearch } from "react-icons/hi";
import { AiOutlineTags } from "react-icons/ai";
import { BsCartCheck, BsCalendar2Day } from "react-icons/bs";
import { TbFileInvoice } from "react-icons/tb";
import { IoIosHelpCircleOutline } from "react-icons/io";
import styled from "styled-components";
import { Badge } from "antd";

const StyledBadge = styled(Badge)`
  margin-left: 10px;
`;

export const sideBarConfigs = [
  {
    type: "item",
    Icon: RxDashboard,
    title: "Dashboard",
    endItem: <StyledBadge count={6} />,
    key: "item1",
    url: "/store/dashboard",
  },
  {
    type: "disabled-item",
    title: "Application",
    key: "item2",
  },
  {
    type: "item",
    Icon: HiOutlineUserGroup,
    title: "Parking",
    key: "item3",
    url: "/store/parking",
  },
  {
    type: "item",
    Icon: AiOutlineTags,
    title: "Product",
    key: "item4",
    url: "/store/menu",
  },
  {
    type: "sub-item",
    Icon: BsCartCheck,
    title: "Order",
    key: "item5",
    subItems: [
      {
        type: "item",
        Icon: HiOutlineUserGroup,
        title: "dog",
      },
    ],
  },
  {
    type: "sub-item",
    Icon: TbFileInvoice,
    title: "Invoice",
    key: "item6",
    subItems: [
      {
        type: "item",
        Icon: HiOutlineUserGroup,
        title: "Customer",
      },
    ],
  },
  {
    type: "item",
    Icon: BsCalendar2Day,
    title: "Calendar",
    key: "item7",
    endItem: <StyledBadge count={"new"} color="#12D764" />,
  },
  {
    type: "disabled-item",
    title: "Support",
    key: "item8",
  },
  {
    type: "item",
    Icon: IoIosHelpCircleOutline,
    title: "Get Help",
    key: "item9",
  },
  {
    type: "item",
    Icon: HiOutlineDocumentSearch,
    title: "Documentation",
    key: "item10",
  },
];
