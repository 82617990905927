import {
  FIND_PARKING_LOT_BY_USER,
  FIND_PARKING_LOT_BY_USER_SUCCESS,
  REGISTER_PARKING_LOT,
} from "./types";
import { action } from "typesafe-actions";

export function registerParkingLotAct(data) {
  return action(REGISTER_PARKING_LOT, data);
}

export function findParkingLotByUserAct() {
  return action(FIND_PARKING_LOT_BY_USER);
}

export function findParkingLotByUserSuccessAct(data) {
  return action(FIND_PARKING_LOT_BY_USER_SUCCESS, data);
}
