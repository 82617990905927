import Axios from "axios";
// import { messageFromResponse } from "../utils/messageFromRes";
// import { showNotification } from "../utils/showNotification";

export const api = (config = {}) => {
  const axios = Axios.create({
    // baseURL: "https://test.fartmart.link",
    // timeout: 5000,
    withCredentials: true,
    ...config,
  });

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (err) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      // showNotification({ type: "error", message: messageFromResponse(err) || "Error" });
      return Promise.reject(err);
    },
  );

  return axios;
};
