import styled from "styled-components";
import { Button } from "antd";

const Total = ({ total }) => {
  return (
    <Wrapper>
      <SubTotalBox border>
        <Title>Subtotal</Title>
        <Title>${total}</Title>
      </SubTotalBox>
      <SubTotalBox>
        <Title weight="600" size="16px">
          Total
        </Title>
        <Title weight="600" size="16px" color="#2f8a33">
          ${total}
        </Title>
      </SubTotalBox>
      <StyledBtn size="large" type="primary">
        Proceed to checkout
      </StyledBtn>
    </Wrapper>
  );
};

const StyledBtn = styled(Button)`
  color: black !important;
  span {
    font-size: 14px;
    font-weight: 500;
  }
  width: 100%;
`;

const Title = styled.div`
  font-size: ${(props) => (props.size ? props.size : "14px")};
  line-height: 1.71429;
  color: ${(props) => (props.size ? props.color : "black")};
  font-weight: ${(props) => (props.weight ? props.weight : "500")};
`;

const SubTotalBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  border-bottom: ${(props) => (props.border ? "1px solid #ededed" : "unset")};
`;

const Wrapper = styled.div`
  padding: 0 30px 30px;
  background-color: #f5f5f5;
  border-radius: 3px;
  width: 370px;
`;

export default Total;
