import { GET_ALL_DISHES_SUCCESS, GET_STORE_TOTAL_DATA_SUCCESS } from "./types";

export default function (state = {}, action = {}) {
  const { type, payload } = action;
  const data = payload?.data?.data || {};
  switch (type) {
    case GET_STORE_TOTAL_DATA_SUCCESS:
      return {
        ...state,
        ...data,
      };
    case GET_ALL_DISHES_SUCCESS:
      return {
        ...state,
        dishes: data,
      };
    default:
      return state;
  }
}
