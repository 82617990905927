import { Modal, Form, Button, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { registerVehicleAct } from "../../../redux/auth/actions";

const RegisterVehicleModal = ({ isModalOpen, handleCancel }) => {
  const { user } = useSelector((state) => state.auth);
  const { identification, phone } = user || {};
  const dispatch = useDispatch();
  const onFinish = (values) => {
    dispatch(registerVehicleAct(values));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Modal footer={null} title="Basic Modal" open={isModalOpen} onCancel={handleCancel}>
      <Form
        initialValues={{
          identification,
          phone,
        }}
        layout={"vertical"}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <Form.Item
          label={<Title>License Plate</Title>}
          name="license_plate"
          rules={[
            {
              required: true,
              message: "Please input your store name!",
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={<Title>Type</Title>}
          name="type"
          rules={[
            {
              required: true,
              message: "Please input your store phone number!",
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={<Title>color</Title>}
          name="color"
          rules={[
            {
              required: true,
              message: "Please input your store address!",
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={<Title>identification</Title>}
          name="identification"
          rules={[
            {
              required: true,
              message: "Please input your store address!",
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={<Title>phone</Title>}
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input your store address!",
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item>
          <JustifyEndBox>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </JustifyEndBox>
        </Form.Item>
      </Form>
    </Modal>
  );
};
const JustifyEndBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Title = styled.div`
  color: #898989;
  text-transform: uppercase;
  font-size: 13px;
`;

export default RegisterVehicleModal;
