import { FIND_PARKING_LOT_BY_USER_SUCCESS } from "./types";

export default function (state = {}, action = {}) {
  const { type, payload } = action;
  const data = payload?.data?.data || {};
  switch (type) {
    case FIND_PARKING_LOT_BY_USER_SUCCESS:
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
}
