import { Modal, Form, Button, Input } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { createDishAct } from "../../../redux/restaurants/actions";
import UploadImage from "../../UploadImage";
import { get } from "lodash";

const CreateDishModal = ({ isModalOpen, handleCancel }) => {
  const [uploadImgList, setUploadImgList] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log("Success:", values, uploadImgList);
    dispatch(
      createDishAct(
        {
          ...values,
          main_image: get(uploadImgList, [0, "response"], ""),
          images: uploadImgList.map((img) => img.response),
        },
        navigate,
      ),
    );
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Modal footer={null} title="Basic Modal" open={isModalOpen} onCancel={handleCancel}>
      <Form
        initialValues={{
          remember: true,
        }}
        layout={"vertical"}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <Form.Item
          label={<Title>DISH NAME</Title>}
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your store name!",
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={<Title>PRICE</Title>}
          name="price"
          rules={[
            {
              required: true,
              message: "Please input your store phone number!",
            },
          ]}>
          <Input type="number" />
        </Form.Item>

        <Form.Item label={<Title>IMAGES</Title>} name="main_image">
          <UploadImage onChangeAdditional={(imgs) => setUploadImgList(imgs)} />
        </Form.Item>

        <Form.Item>
          <JustifyEndBox>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </JustifyEndBox>
        </Form.Item>
      </Form>
    </Modal>
  );
};
const JustifyEndBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Title = styled.div`
  color: #898989;
  text-transform: uppercase;
  font-size: 13px;
`;

export default CreateDishModal;
