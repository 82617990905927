export const esentialProductList = {
  additionalTitle: [
    "All",
    "Fruits & Vegetables",
    "Frozen Seafoods",
    "Raw Meats",
    "Coffes & Teas",
    "Pet Foods",
    "Milks & Dairies",
  ],
  title: "Essential Product",
  numberOfDisplayItems: 6,
  list: [
    {
      src: "/images/01_16a.jpg",
      name: "Bar S – Classic Bun Lead",
      star: 4,
      price: 35.0,
      _id: "12124234",
    },
    {
      src: "/images/01_18a.jpg",
      name: "Saute Pan Silver",
      star: 4.7,
      price: 1.9,
      _id: "12124232",
    },
    {
      src: "/images/03_3a.jpg",
      name: "Broccoli Crowns",
      star: 4.9,
      price: 10.0,
      _id: "121242345435",
    },
    {
      src: "/images/04_4a.jpg",
      name: "Häagen-Dazs Salted Caramel",
      star: 4.3,
      price: 11.57,
      _id: "1212426457567",
    },
    {
      src: "/images/05_4a.jpg",
      name: "Iceland 3 Solo Exotic Burst",
      star: 4.2,
      price: 11.57,
      _id: "12124234765765",
    },
    {
      src: "/images/06_3a-600x600.jpg",
      name: "Slimming World Vegan Gre...",
      star: 4.5,
      price: 3.38,
      _id: "1212423453464526",
    },
    {
      src: "/images/01_16a.jpg",
      name: "Bar S – Classic Bun Lead",
      star: 4,
      price: 35.0,
      _id: "12124234685456",
    },
    {
      src: "/images/01_18a.jpg",
      name: "Saute Pan Silver",
      star: 4.7,
      price: 1.9,
      _id: "12124234214123423",
    },
    {
      src: "/images/03_3a.jpg",
      name: "Broccoli Crowns",
      star: 4.9,
      price: 10.0,
      _id: "12124234234234",
    },
    {
      src: "/images/04_4a.jpg",
      name: "Häagen-Dazs Salted Caramel",
      star: 4.3,
      price: 11.57,
      _id: "121242344324234",
    },
    {
      src: "/images/05_4a.jpg",
      name: "Iceland 3 Solo Exotic Burst",
      star: 4.2,
      price: 11.57,
      _id: "12124234111111",
    },
    {
      src: "/images/06_3a-600x600.jpg",
      name: "Slimming World Vegan Gre...",
      star: 4.5,
      price: 3.38,
      _id: "121242234234534",
    },
  ],
};
