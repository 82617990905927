import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { loginAct } from "../../redux/auth/actions";
import { useCustomCookie } from "../../customHooks/useCustomCookie";
const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, setCookie] = useCustomCookie("Authentication");
  const onFinish = (values) => {
    dispatch(loginAct(values, navigate, setCookie));
  };
  const navigateToRegister = () => {
    navigate("/register");
  };
  return (
    <Wapper>
      <Container>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}>
          <Form.Item
            name="user_name"
            rules={[{ required: true, message: "Please input your Username!" }]}>
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}>
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <ForgotBox>
              <ForgotContent className="login-form-forgot" href="">
                Forgot password
              </ForgotContent>
            </ForgotBox>
          </Form.Item>

          <Form.Item>
            <StyledLoginBtn type="primary" htmlType="submit" className="login-form-button">
              Log in
            </StyledLoginBtn>
            Or <a onClick={navigateToRegister}>register now!</a>
          </Form.Item>
        </Form>
      </Container>
    </Wapper>
  );
};
const ForgotContent = styled.a`
  float: right;
`;
const ForgotBox = styled.div`
  display: inline;
`;
const StyledLoginBtn = styled(Button)`
  width: 100%;
`;
const Wapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Container = styled.div`
  height: 500px;
  width: 400px;
  padding: 20px;
`;

export default LoginPage;
