import { Form, Input } from "antd";
import styled from "styled-components";

const TextBox = ({ label, required, name, placeholder, prefix, suffix, rules }) => {
  return (
    <StyledForm.Item rules={rules} label={label} required={required} name={name}>
      <StyledInput suffix={suffix} prefix={prefix} placeholder={placeholder} />
    </StyledForm.Item>
  );
};

const StyledForm = styled(Form)``;

const StyledInput = styled(Input)``;

export default TextBox;
