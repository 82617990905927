import React, { useEffect, useState } from "react";
import { Upload } from "antd";
import { RESTAURANT_URL } from "../../services/urls";

const UploadImage = ({ onChangeAdditional }) => {
  const [fileList, setFileList] = useState([]);
  const onChange = async (values) => {
    const { fileList: newFileList } = values;
    setFileList(newFileList);
    onChangeAdditional(newFileList);
  };

  useEffect(() => {
    console.log({ fileList });
  }, [fileList]);

  return (
    <Upload
      action={`${RESTAURANT_URL}/upload-image`}
      withCredentials
      listType="picture-card"
      fileList={fileList}
      onChange={onChange}>
      {fileList.length < 5 && "+ Upload"}
    </Upload>
  );
};

export default UploadImage;
