import React, { useState } from "react";
import { Upload, Form, Button } from "antd";
import { transformImageUrl } from "../../../../utils/transformImageUrl";
import { UploadOutlined } from "@ant-design/icons";
import styled from "styled-components";

const ImagesBox = ({ label, name, currentValues = [], uploadUrl }) => {
  const [fileList, setFileList] = useState(
    currentValues.map((currentValue) => transformImageUrl(currentValue)),
  );
  const onChange = async (values) => {
    const { fileList: newFileList } = values;
    setFileList(newFileList);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Form.Item label={label} name={name} valuePropName="fileList" getValueFromEvent={normFile}>
      <StyledUpload
        action={uploadUrl}
        withCredentials
        listType="picture"
        // listType="picture-card"
        fileList={fileList}
        onChange={onChange}>
        {fileList.length < 5 && (
          <StyledButton icon={<UploadOutlined />}>Click to Upload</StyledButton>
        )}
      </StyledUpload>
    </Form.Item>
  );
};

const StyledUpload = styled(Upload)`
  a {
    text-decoration: unset;
    color: rgb(154, 85, 255);
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
`;

export default ImagesBox;
