import { LOGOUT, LOGIN, REGISTER, REGISTER_VEHICLE, GET_PROFILE } from "./types";
import AuthService from "../../services/auth.service";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { getProfileAct, getProfileFailAct, getProfileSuccessAct } from "./actions";
import { showNotification } from "../../utils/showNotification";
import { messageFromResponse } from "../../utils/messageFromRes";

export function* register({ payload }) {
  try {
    const { user_name, password, role, navigate } = payload;
    const result = yield call(AuthService.register, { user_name, password, role });
    showNotification({ type: "success", message: messageFromResponse(result) });
    navigate("/login");
  } catch (err) {
    console.log({ saga: "register", err });
  }
}

export function* getProfile() {
  try {
    const result = yield call(AuthService.getProfile);
    yield put(getProfileSuccessAct(result));
  } catch (err) {
    yield put(getProfileFailAct());
    console.log({ saga: "getProfile", err });
  }
}

export function* registerVehicle({ payload }) {
  try {
    const result = yield call(AuthService.registerVehicle, payload);
    showNotification({ type: "success", message: messageFromResponse(result) });
  } catch (err) {
    console.log({ saga: "registerVehicle", err });
  }
}

export function* login({ payload }) {
  try {
    const { user_name, password, navigate, setCookie } = payload;
    const result = yield call(AuthService.login, { user_name, password });
    navigate("/");
    yield put(getProfileAct());
    showNotification({ type: "success", message: messageFromResponse(result) });
    const { configs, token } = result?.data?.data || {};
    setCookie("Authentication", token, configs);
  } catch (err) {
    console.log({ saga: "login", err });
  }
}

export function* logout() {
  yield call(AuthService.logout);
  yield put(getProfileFailAct());
}

export function* getProfileSaga() {
  yield takeLatest(GET_PROFILE, getProfile);
}

export function* loginSaga() {
  yield takeLatest(LOGIN, login);
}

export function* registerSaga() {
  yield takeLatest(REGISTER, register);
}

export function* registerVehicleSaga() {
  yield takeLatest(REGISTER_VEHICLE, registerVehicle);
}

export function* logoutSaga() {
  yield takeLatest(LOGOUT, logout);
}

export function* root() {
  yield all([loginSaga(), registerSaga(), logoutSaga(), registerVehicleSaga(), getProfileSaga()]);
}

export default root;
