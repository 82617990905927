import { useLocation } from "react-router-dom";
import styled from "styled-components";
import DropDownIcon from "../../../assets/images/down-arrow.png";
import { Dropdown } from "antd";

const MenuBox = ({ Icon, type, content, hoverColor, url, items }) => {
  const location = useLocation();
  return (
    <>
      {type === "link" && (
        <Wrapper color={location.pathname === url ? hoverColor : "#000"} hoverColor={hoverColor}>
          {Icon && <Icon style={{ marginRight: "5px" }} />}
          <Content>{content}</Content>
        </Wrapper>
      )}
      {type === "dropdown" && (
        <StyledDropdown menu={{ items }} placement="bottomLeft">
          <Wrapper color={location.pathname === url ? hoverColor : "#000"} hoverColor={hoverColor}>
            <Content>{content}</Content>
            <StyledImg src={DropDownIcon} />
          </Wrapper>
        </StyledDropdown>
      )}
    </>
  );
};

// const DropdownLine = styled.div`
//   width: 200px;
//   background-color: red;
// `;

const StyledDropdown = styled(Dropdown)`
  padding: 0px 20px;
  ul {
    min-width: 300px;
    width: 300px !important;
  }
`;

const Content = styled.span`
  font-weight: 500;
  white-space: nowrap;
`;

const StyledImg = styled.img`
  height: 12px;
  width: 12px;
  margin-left: 5px;
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  padding: 11px 21px;
  border-style: none;
  color: ${(props) => props.color || "#000"};
  align-items: center;
  font-weight: 700;
  white-space: nowrap;
  transition: all 0.5s;
  &:hover {
    color: ${(props) => (props.hoverColor ? props.hoverColor : "#000")};
    ${StyledImg} {
      opacity: 0;
    }
  }
`;

export default MenuBox;
