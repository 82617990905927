import { ThunderboltOutlined, TagOutlined } from "@ant-design/icons";
import styled from "styled-components";

const Line = styled.div`
  min-width: 150px;
`;
export const customerHeaderConfig = [
  {
    content: "Deals Today",
    icon: ThunderboltOutlined,
    type: "link",
    url: "/deals",
  },
  {
    icon: TagOutlined,
    content: "Special Prices",
    type: "link",
    url: "/special",
  },
  {
    content: "Fresh",
    type: "dropdown",
    items: [
      {
        key: "1",
        label: <Line>Fruit</Line>,
      },
      {
        key: "2",
        label: <Line>Meat & Poultry</Line>,
      },
      {
        key: "3",
        label: <Line>Milk, Butter & Eggs</Line>,
      },
      {
        key: "4",
        label: <Line>Vegetables</Line>,
      },
    ],
  },
  {
    content: "Frozen",
    type: "dropdown",
    items: [
      {
        key: "1",
        label: <Line>Buitoni</Line>,
      },
      {
        key: "2",
        label: <Line>Buttercream</Line>,
      },
      {
        key: "3",
        label: <Line>Freshly</Line>,
      },
      {
        key: "4",
        label: <Line>Hot Pockets</Line>,
      },
    ],
  },
  {
    content: "Demos",
    type: "link",
    url: "/",
  },
  {
    content: "Shop",
    type: "dropdown",
    items: [
      {
        key: "1",
        label: <Line>Shop</Line>,
      },
      {
        key: "2",
        label: <Line>Cart</Line>,
      },
      {
        key: "3",
        label: <Line>Checkout</Line>,
      },
      {
        key: "4",
        label: <Line>Single Product</Line>,
      },
    ],
  },
  {
    content: "Blog",
    type: "dropdown",
    items: [
      {
        key: "1",
        label: <Line>Blog Default</Line>,
      },
      {
        key: "2",
        label: <Line>Single Post</Line>,
      },
    ],
  },
  {
    content: "Pages",
    type: "dropdown",
    items: [
      {
        key: "1",
        label: <Line>About Us</Line>,
      },
      {
        key: "2",
        label: <Line>Contact Us</Line>,
      },
      {
        key: "3",
        label: <Line>FAQs</Line>,
      },
      {
        key: "4",
        label: <Line>Pages 404</Line>,
      },
      {
        key: "5",
        label: <Line>Coming Soon</Line>,
      },
    ],
  },
  {
    content: "Brand",
    type: "link",
  },
];
