import { useLocation } from "react-router-dom";
import CustomerLayout from "./customer";
import StoreOwnerLayout from "./storeOwner";

const CustomLayout = ({ children }) => {
  const location = useLocation();
  const AppliedLayout = location.pathname.includes("/store/") ? StoreOwnerLayout : CustomerLayout;
  return <AppliedLayout>{children}</AppliedLayout>;
};

export default CustomLayout;
