import styled from "styled-components";
import { Badge, Avatar, Dropdown, Tooltip } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import { createDishAct, updateDishAct } from "../../redux/restaurants/actions";
import { get } from "lodash";
import { transformImageUrl } from "../../utils/transformImageUrl";
import { RESTAURANT_URL } from "../../services/urls";
import { productTags } from "../constants";

const GrayText = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: #a0a0a0;
`;

const NoteName = styled.div`
  color: #9a9a9a;
  line-height: 8px;
  font-size: 12px;
`;

const Name = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: black;
  line-height: 9px;
  margin-bottom: 9px;
`;

const NameBox = styled.div``;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CustomAvatar = styled.img`
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin-right: 10px;
`;

const ActionBtn = styled.div`
  color: ${(props) => props.color};
  width: 100px;
`;

const actions = (showDrawer, record) => [
  {
    label: (
      <ActionBtn onClick={() => showDrawer("update-product", record)} color="black">
        Update
      </ActionBtn>
    ),
    key: "0",
  },
  {
    label: <ActionBtn color="red">Delete</ActionBtn>,
    key: "1",
  },
];

export const tableProductConfigs = () => ({
  header: {
    title: "Products",
    note: "This table display your store's products and help you handle them!",
    buttons: [
      {
        title: "Add new Product",
        form: "add-product",
      },
    ],
  },
  columns: (showDrawer) => [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <NameWrapper>
          <CustomAvatar src={record.main_image} />
          <NameBox>
            <Name>{text}</Name>
            <NoteName>@1124</NoteName>
          </NameBox>
        </NameWrapper>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) =>
        text === "ACTIVE" ? (
          <Badge style={{ color: "#2362ee" }} count={"active"} color={"#ebf2fd"} />
        ) : (
          <Badge style={{ color: "red" }} count={"inactive"} color={"rgb(255 224 225)"} />
        ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => <GrayText>{text}$</GrayText>,
    },
    {
      title: "Images",
      dataIndex: "images",
      key: "images",
      render: (text) => (
        <Avatar.Group size={25}>
          {text.map((image) => (
            <Avatar key={image} src={image} style={{ backgroundColor: "#87d068" }} />
          ))}
        </Avatar.Group>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <Dropdown menu={{ items: actions(showDrawer, record) }} trigger={["click"]}>
          <a style={{ color: "black" }} onClick={(e) => e.preventDefault()}>
            <BsThreeDotsVertical />
          </a>
        </Dropdown>
      ),
    },
  ],
  forms: [
    {
      name: "add-product",
      transformDataBeforeSend: (data) => {
        return {
          ...data,
          main_image: get(data, ["images", 0, "response"], ""),
          images: data?.images.map((image) => image.response),
        };
      },
      fields: [
        {
          label: "Name",
          name: "name",
          type: "string",
          prefix: <UserOutlined className="site-form-item-icon" />,
          suffix: (
            <Tooltip title="Extra information">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          ),
          placeholder: "Enter your username",
          rules: [{ required: true, message: "Please input your username!" }],
        },
        {
          label: "Price",
          name: "price",
          type: "number",
          prefix: <UserOutlined className="site-form-item-icon" />,
          suffix: (
            <Tooltip title="Extra information">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          ),
          placeholder: "Enter your username",
          rules: [{ required: true, message: "Please input your username!" }],
        },
        {
          label: "Status",
          name: "status",
          type: "select",
          width: 120,
          options: [
            { value: "ACTIVE", label: "active" },
            { value: "INACTIVE", label: "inactive" },
          ],
        },
        {
          label: "Tags",
          name: "tags",
          type: "select-multiple",
          options: Object.entries(productTags).map(([key, value]) => ({
            value: value,
            label: key,
          })),
        },
        {
          label: "Images",
          name: "images",
          type: "images",
          uploadUrl: `${RESTAURANT_URL}/upload-image`,
        },
      ],
      btnTitle: "Create",
      action: createDishAct,
    },
    {
      name: "update-product",
      transformDataBeforeSend: (data) => {
        return {
          ...data,
          main_image:
            get(data, ["images", 0, "response"], "") || get(data, ["images", 0, "url"], ""),
          images: data?.images.map((image) => image.response || image.url),
        };
      },
      transformDefaultData: (data) => ({
        ...data,
        images: data.images.map((image) => transformImageUrl(image)),
      }),
      fields: [
        {
          label: "Name",
          name: "name",
          type: "string",
          prefix: <UserOutlined className="site-form-item-icon" />,
          suffix: (
            <Tooltip title="Extra information">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          ),
          placeholder: "Enter your username",
          rules: [{ required: true, message: "Please input your username!" }],
        },
        {
          label: "Price",
          name: "price",
          type: "number",
          prefix: <UserOutlined className="site-form-item-icon" />,
          suffix: (
            <Tooltip title="Extra information">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          ),
          placeholder: "Enter your username",
          rules: [{ required: true, message: "Please input your username!" }],
        },
        {
          label: "Status",
          name: "status",
          type: "select",
          width: 120,
          options: [
            { value: "ACTIVE", label: "active" },
            { value: "INACTIVE", label: "inactive" },
          ],
        },
        {
          label: "Images",
          name: "images",
          type: "images",
          uploadUrl: `${RESTAURANT_URL}/upload-image`,
        },
      ],
      btnTitle: "Update",
      action: updateDishAct,
    },
  ],
});
