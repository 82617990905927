import { Button } from "antd";
import styled from "styled-components";

const CustomButton = ({ content, onClick = () => {}, type, htmlType }) => {
  return (
    <StyledButton htmlType={htmlType} type={type} onClick={onClick}>
      {content}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  height: unset;
  background-color: rgb(154, 85, 255);
  &:hover {
    background-color: rgb(170 113 253) !important;
  }
`;

export default CustomButton;
