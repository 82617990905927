import { Navigate, useLocation } from "react-router-dom";
import { useCustomCookie } from "./customHooks/useCustomCookie";

const ProtectedRoute = ({ Element }) => {
  const [authentication] = useCustomCookie("Authentication");
  const location = useLocation();

  if (!authentication) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return <Element />;
};

export default ProtectedRoute;
