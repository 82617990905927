import styled from "styled-components";

const HomePagebrand = () => {
  return (
    <Wrapper>
      <Block style={{ backgroundImage: "url(/images/mango.jpg)" }}>
        <Container>
          <Title>
            Mango Juice <br /> Bottle
          </Title>
          <Note>
            Lorem ispum dolor
            <br /> sit amet
          </Note>
        </Container>
      </Block>
      <Block style={{ backgroundImage: "url(/images/free-food.jpg)" }}>
        <Container>
          <FlexBox>
            <FirstSpan>$9,9</FirstSpan>
            <SecondSpan>$15.6</SecondSpan>
          </FlexBox>
          <SecondTitle>
            meat pork
            <br /> canned
          </SecondTitle>
          <SecondNote>250g</SecondNote>
        </Container>
      </Block>
      <Block style={{ backgroundImage: "url(/images/oliveoil.jpg)" }}>
        <Container>
          <ThirdTitle>olive oil</ThirdTitle>
          <ThirdNote>
            Best product to make
            <br /> your favor
          </ThirdNote>
          <Price>$12.5</Price>
        </Container>
      </Block>
    </Wrapper>
  );
};
const Price = styled.div`
  padding: 0;
  font-size: 30px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -1px;
  color: #fff;
  background-color: #02010100;
  margin-top: 28px;
`;
const ThirdNote = styled.div`
  margin-bottom: 45px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -1px;
`;
const ThirdTitle = styled.div`
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  line-height: 48px;
  letter-spacing: -1px;
  color: #fff;
`;
const SecondNote = styled.div`
  padding: 0;
  font-weight: 400;
  color: #666;
  background-color: #02010100;
  font-size: 13px;
  margin-top: 28px;
`;
const SecondTitle = styled.div`
  margin-bottom: 40px;
  color: #000;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: -1px;
  font-weight: 500;
`;
const SecondSpan = styled.span`
  font-size: 18px;
  margin-top: 16px;
  text-decoration: line-through;
  letter-spacing: -1px;
  display: inline-block;
  color: #000;
  text-shadow: 0 0 #000;
  margin-left: 15px;
`;

const FirstSpan = styled.span`
  margin-bottom: 18px;
  color: #fa532c;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  line-height: 48px;
  letter-spacing: -1px;
`;

const FlexBox = styled.div`
  display: flex;
`;

const Note = styled.div`
  padding: 0;
  color: #000;
  background-color: #02010100;
  font-size: 13px;
  font-weight: 500;
  margin-top: 28px;
`;

const Title = styled.div`
  margin-bottom: 40px;
  color: #000;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -1px;
  font-weight: 500;
`;

const Container = styled.div`
  width: 82%;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Block = styled.div`
  width: 33%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  border-radius: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 25px 0px;
  justify-content: space-between;
`;

export default HomePagebrand;
