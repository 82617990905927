import styled from "styled-components";
import { Input } from "antd";

const UsingCouon = () => {
  return (
    <div>
      <Coupon>Using A Promo Code?</Coupon>
      <InputCoupon
        width={500}
        placeholder="Coupon code"
        allowClear
        enterButton="Apply"
        size="large"
        onSearch={() => {}}
      />
    </div>
  );
};

const InputCoupon = styled(Input.Search)`
  width: 350px;
  font-size: 14px !important;
  span {
    span {
      button {
        color: black;
        font-weight: 600;
        padding: 0 10px !important;
        width: 90px;
        line-height: 40px;
        font-size: 14px !important;
      }

      input {
        color: gray;
        padding: 0 10px !important;
        width: 90px;
        line-height: 40px;
        font-size: 14px !important;
      }
    }
  }
`;

const Coupon = styled.div`
  color: #000;
  font-size: 22px;
  font-weight: 700;
  display: block;
  margin-bottom: 20px;
  margin-top: 14px;
  line-height: 1.71429;
`;

export default UsingCouon;
