import { Form } from "antd";
import NumberBox from "./elements/Number";
import TextBox from "./elements/Text";
import styled from "styled-components";
import ImagesBox from "./elements/Images";
import CustomButton from "../../Share/customButton";
import SelectBox from "./elements/Select";
import SelectMultipleBox from "./elements/SelectMultiple";
import { useEffect } from "react";

const renderStoreForm = (btnTitle, fields = [], submitForms, defaultData) => {
  const [form] = Form.useForm();
  const renderType = (type) => {
    switch (type) {
      case "string" || "text":
        return TextBox;
      case "number":
        return NumberBox;
      case "images":
        return ImagesBox;
      case "select":
        return SelectBox;
      case "select-multiple":
        return SelectMultipleBox;
      default:
        return TextBox;
    }
  };
  const renderElement = ({ type, ...config }, index) => {
    const Element = renderType(type);
    return <Element key={index + type} {...config} defaultData={defaultData} />;
  };
  useEffect(() => {
    if (Object.keys(defaultData).length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue({ ...defaultData });
    }
  }, [form, defaultData]);
  return (
    <StyledForm initialValues={defaultData} form={form} layout="vertical" onFinish={submitForms}>
      <MaxHeight>{fields.map((field, index) => renderElement(field, index))}</MaxHeight>
      <Form.Item>
        <CustomButton content={btnTitle} htmlType="submit" type="primary" />
      </Form.Item>
    </StyledForm>
  );
};

const MaxHeight = styled.div`
  /* height: 100%; */
  overflow: scroll;
`;

const StyledForm = styled(Form)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

export default renderStoreForm;
