import styled from "styled-components";
import { Dropdown, Input } from "antd";
import { SmileOutlined, SearchOutlined } from "@ant-design/icons";
import DropDownIcon from "../../../assets/images/down-arrow.png";

const items = [
  {
    key: "1",
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
        1st menu item
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
        2nd menu item (disabled)
      </a>
    ),
    icon: <SmileOutlined />,
    disabled: true,
  },
  {
    key: "3",
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
        3rd menu item (disabled)
      </a>
    ),
    disabled: true,
  },
  {
    key: "4",
    danger: true,
    label: "a danger item",
  },
];

const SearchBox = () => {
  return (
    <Wrapper>
      <DropdownBox>
        <DropdownContainer>
          <Dropdown menu={{ items }}>
            <DropdownContentBox onClick={(e) => e.preventDefault()}>
              ALL CATEGORIES
              <StyledImg src={DropDownIcon} />
            </DropdownContentBox>
          </Dropdown>
        </DropdownContainer>
      </DropdownBox>
      <InputBox>
        <InputContainer>
          <Input bordered={false} placeholder="I'm searching for..." />
        </InputContainer>
      </InputBox>
      <SearchIconBox>
        <SearchOutlined style={{ fontSize: "18px" }} />
      </SearchIconBox>
    </Wrapper>
  );
};

const SearchIconBox = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  background-color: #f7f7f7;
`;

const InputContainer = styled.div`
  margin: 10px 0px;
  padding: 0px 10px;
  border-radius: 5px 0px 0px 5px;
  width: 100%;
`;

const InputBox = styled.div`
  height: 50px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledImg = styled.img`
  height: 12px;
  width: 12px;
  margin-left: 15px;
`;

const DropdownContentBox = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
`;

const DropdownContainer = styled.div`
  margin: 10px 0px;
  padding: 0px 10px;
  border-right: 1px solid #c5c5c5;
  border-radius: 5px 0px 0px 5px;
`;

const DropdownBox = styled.div`
  height: 50px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  min-width: 165px;
`;

const Wrapper = styled.div`
  display: flex;
  font-family: "Mulish", sans-serif;
  width: 42.5%;
`;

export default SearchBox;
