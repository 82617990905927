import styled from "styled-components";
import { StarFilled } from "@ant-design/icons";
import { AiFillHeart } from "react-icons/ai";

const StoreBlock = ({ data }) => {
  const { src, title, note, star, heart, time } = data;
  return (
    <Wrapper>
      <ImgBox src={src} />
      <InfoWrapper>
        <InfoBox>
          <Avatar src={src} />
          <NameBox>
            <Name>{title}</Name>
            <Address>{note}</Address>
          </NameBox>
        </InfoBox>
        <StarBox>
          <StyledStarFilled />
          <Star>{star}/5</Star>
        </StarBox>
      </InfoWrapper>
      <LikeTimeBox>
        <LikeBox>
          <StyledHeartOutLined />
          <HeartText>{heart}</HeartText>
        </LikeBox>
        <TimeBox>
          {time} min <TimeDot />
        </TimeBox>
      </LikeTimeBox>
    </Wrapper>
  );
};

const HeartText = styled.div`
  font-size: 13px;
`;

const StyledHeartOutLined = styled(AiFillHeart)`
  font-size: 18px;
  margin-right: 5px;
  color: red;
`;
const StyledStarFilled = styled(StarFilled)`
  padding-top: 2px;
  padding-right: 2px;
  color: #ffe918;
  display: flex;
  flex-direction: column;
`;

const TimeDot = styled.div`
  border-radius: 50%;
  margin-top: 2px;
  height: 5px;
  width: 5px;
  background-color: gray;
  margin-left: 10px;
`;

const TimeBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: gray;
`;

const LikeBox = styled.div`
  display: flex;
  align-items: center;
  color: gray;
`;

const LikeTimeBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
`;

const Star = styled.p`
  line-height: 18px;
`;

const StarBox = styled.div`
  display: flex;
  margin-left: 6px;
  padding-top: 2px;
`;

const Address = styled.div`
  color: #707c88;
  margin: 0;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Name = styled.div`
  line-height: 22px;
  font-size: 17px;
  color: #292f48;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NameBox = styled.div``;

const Avatar = styled.img`
  border-radius: 5px;
  margin-right: 15px;
  background-color: gray;
  height: 40px;
  width: 40px;
`;

const InfoBox = styled.div`
  display: flex;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
  border-bottom: 1px solid #f1f1f1;
`;

const ImgBox = styled.img`
  background-color: gray;
  height: 200px;
  width: 100%;
  border-radius: 12px;
`;

const Wrapper = styled.div`
  cursor: pointer;
  font-family: "ProximaNova", sans-serif;
  padding: 15px;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 2px 11px 0 rgb(0 0 0 / 14%);
  }
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
  width: 400px;
`;

export default StoreBlock;
