import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import CustomTable from "../../components/tables";
import { tableProductConfigs } from "../../configs/tables/tableProductConfigs";
// import { findParkingLotByUserAct } from "../../redux/parking/actions";
import styled from "styled-components";
import { io } from "socket.io-client";
import ParkingCarBlock from "../../components/blocks/parkingCarBlock";
import { useSelector } from "react-redux";

const StoreParking = () => {
  // const dispatch = useDispatch();
  const [socket, setSocket] = useState();
  const [messages, setMessages] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const { store_id } = user || {};
  // useEffect(() => {
  //   dispatch(findParkingLotByUserAct());
  // }, []);
  useEffect(() => {
    const newSocket = io("http://localhost:8007/polls", {
      withCredentials: true,
    });
    setSocket(newSocket);
  }, []);
  const messageListener = (message) => {
    console.log("receive", message);
    setMessages([...messages, message]);
  };
  useEffect(() => {
    socket?.on(store_id, messageListener);
    socket?.emit("message", "open");
    return () => {
      socket?.off(store_id, messageListener);
    };
  }, [socket]);
  return (
    <CustomTable configs={tableProductConfigs()} data={[]}>
      <ParkingCarBox>
        <ParkingCarBlock title={"parking spaces used"} />
        <ParkingCarBlock title={"unused parking spaces"} />
        <ParkingCarBlock title={"total parking spaces"} opacity={0.5} />
        <ParkingCarBlock title={"tables used"} />
        <ParkingCarBlock title={"unused tables"} />
        <ParkingCarBlock title={"total tables"} opacity={0.5} />
      </ParkingCarBox>
    </CustomTable>
  );
};

const ParkingCarBox = styled.div`
  display: grid;
  grid-template-columns: 16.6% 16.6% 16.6% 16.6% 16.6% 16.6%;
  grid-gap: 10px;
  margin-bottom: 25px;
`;

export default StoreParking;
