import React, { useState } from "react";
import { STORE_MENU_SALE } from "../configs/constants";

const MenuStoreContext = React.createContext();

const MenuStoreProvider = ({ children }) => {
  const [status, setStatus] = useState(STORE_MENU_SALE);
  return (
    <MenuStoreContext.Provider
      value={{
        status,
        setStatus,
      }}>
      {children}
    </MenuStoreContext.Provider>
  );
};

export { MenuStoreProvider, MenuStoreContext };
