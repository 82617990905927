import styled from "styled-components";
import UserInfo from "./UserInfo";
import { Divider } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import MenuBox from "../Share/MenuBox";
import SearchBox from "../search/headerSearch";
import { customerHeaderConfig } from "../../configs/headers/headerCustomer";
import "./index.css";
import ShopByCategory from "./shopByCategory/index";

const Header = () => {
  return (
    <Wrapper>
      <WrapperSearchBox>
        <LogoBox>
          <LogoContainer>
            <HomePageLink href="/">
              <Logo src="/images/logo.svg" />
            </HomePageLink>
          </LogoContainer>
        </LogoBox>
        <SearchBox />
        <UserInfo />
      </WrapperSearchBox>
      <Divider style={{ margin: 0 }}></Divider>
      <MenuBoxWrapper>
        <MenuPart>
          <ShopByCategory />
        </MenuPart>
        <MenuGroupBox>
          {customerHeaderConfig.map((menu, index) => {
            return (
              <MenuBox
                items={menu.items}
                key={`${index}-${menu.content}`}
                hoverColor={"#fab528"}
                content={menu.content}
                Icon={menu.icon}
                url={menu.url}
                type={menu.type}
              />
            );
          })}
        </MenuGroupBox>
        <RecentView>
          <SyncOutlined />
          <RecentViewContent>Recently Viewed</RecentViewContent>
        </RecentView>
      </MenuBoxWrapper>
    </Wrapper>
  );
};

const RecentViewContent = styled.span`
  font-size: 14px;
  margin-left: 10px;
  color: #000;
  font-weight: 500;
  white-space: nowrap;
`;

const RecentView = styled.div`
  display: flex;
  align-items: center;
`;

const MenuGroupBox = styled.div`
  display: flex;
  width: 100%;
`;

const MenuPart = styled.div`
  display: flex;
`;

const Logo = styled.img`
  width: 155px;
  height: 45px;
`;

const HomePageLink = styled.a`
  position: relative;
`;

const LogoBox = styled.div`
  width: 20%;
  padding-right: 30px;
  display: flex;
`;

const LogoContainer = styled.div`
  flex: 0 1 auto;
  position: relative;
  z-index: 100;
`;

const MenuBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px 12px;
  margin: 0px 70px;
`;

const WrapperSearchBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 38.5px 0;
  margin: 0px 70px;
`;

const Wrapper = styled.div`
  background-color: white;
`;

export default Header;
