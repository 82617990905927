export const categoriesListConfig = [
  {
    type: "menu",
    label: "Daily Deals",
  },
  {
    type: "menu",
    label: "Top Promotions",
  },
  {
    type: "menu",
    label: "New Arrivals",
  },
  {
    type: "submenu",
    label: "Fruits & Vegetables",
    popupOffset: [28, -114],
    backgroundImg: "/images/department-menu-bg-1.jpg",
    subMenus: [
      {
        title: "Fruit",
        listMenu: [
          "Apples",
          "Bananas",
          "Beries",
          "Oranges & Easy Peelers",
          "Grapes",
          "Lemons & Limes",
          "Peaches & Nectarines",
          "Pears",
          "Melon",
          "Avocados",
          "Plums & Apricots",
          {
            type: "orange",
            name: <div>See all Products {">"}</div>,
          },
        ],
      },
      {
        title: "Vegetables",
        listMenu: [
          "Potatoes",
          "Carrots & Root Vegetables",
          "Broccoli & Cauliflower",
          "Cabbege, Spinach & Greens",
          "Onion, Leeks & Galic",
          "Mushrooms",
          "Tomotoes",
          "Beans, Peas & Sweetcorn",
          {
            type: "button",
            name: "FRESHLY DRINK ORANGE JUICE",
            note: "Instant Drink",
          },
        ],
      },
    ],
  },
  {
    type: "submenu",
    label: "Breads Sweets",
    popupOffset: [28, -147],
    subMenus: [
      {
        title: "Crisps, Snacks & Nuts",
        listMenu: [
          "Crisps & Popcorn",
          "Nuts & Seeds",
          "Lighter Options",
          "Cereal Bars",
          "Breadsticks & Pretzels",
          "Fruit Snacking",
          "Rice & Corn Cakes",
          "Protein & Energy Snacks",
          "Toddler Snacks",
          "Beans",
          "Lentils",
          "Chickpeas",
          {
            type: "orange",
            name: <div>See all Products {">"}</div>,
          },
        ],
      },
      {
        title: "Vegetables",
        listMenu: [
          "Tins & Cans",
          "Tomatoes",
          "Baked Beans, Spaghetti",
          "Fish",
          "Beans & Pulses",
          "Fruit",
          "Coconut Milk & Cream",
          "Lighter Options",
          "Olives",
          "Sweetcorn",
          "Carrots",
          "Peas",
          "Mixed Vegetables",
        ],
      },
      {
        width: "300px",
        title: "Image",
        listMenu: [{ type: "image", link: "/images/department-menu-img-1.jpg" }],
      },
    ],
  },
  {
    type: "menu",
    label: "Frozen Seafoods",
  },
  {
    type: "menu",
    label: "Raw Meats",
  },
  {
    type: "submenu",
    label: "Wines & Alcohol Drinks",
    popupOffset: [28, -246],
    subMenus: [
      {
        title: "Crisps, Snacks & Nuts",
        listMenu: [
          "Crisps & Popcorn",
          "Nuts & Seeds",
          "Lighter Options",
          "Cereal Bars",
          "Breadsticks & Pretzels",
          "Fruit Snacking",
          "Rice & Corn Cakes",
          "Protein & Energy Snacks",
          "Toddler Snacks",
          "Beans",
          "Lentils",
          "Chickpeas",
          {
            type: "orange",
            name: <div>See all Products {">"}</div>,
          },
        ],
      },
      {
        title: "Vegetables",
        listMenu: [
          "Tins & Cans",
          "Tomatoes",
          "Baked Beans, Spaghetti",
          "Fish",
          "Beans & Pulses",
          "Fruit",
          "Coconut Milk & Cream",
          "Lighter Options",
          "Olives",
          "Sweetcorn",
          "Carrots",
          "Peas",
          "Mixed Vegetables",
        ],
      },
      {
        width: "300px",
        title: "Image",
        listMenu: [{ type: "image", link: "/images/department-menu-img-1.jpg" }],
      },
    ],
  },
  {
    type: "menu",
    label: "tea & Coffee",
  },
  {
    type: "menu",
    label: "Milks and Dairies",
  },
  {
    type: "menu",
    label: "Pet Foods",
  },
  {
    type: "menu",
    label: "Food Cupboard",
  },
];
