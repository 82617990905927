import { Table, Drawer } from "antd";
import styled from "styled-components";
import TableHeader from "./Header";
import { useState } from "react";
import renderStoreForm from "../forms/store";
import { get } from "lodash";
import { useDispatch } from "react-redux";

const CustomTable = ({ data = [], configs = {}, children }) => {
  const dispatch = useDispatch();
  const [defaultData, setDefaultData] = useState({});
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(get(configs, ["forms", 0], {}));
  const changeForm = (formName, defaultFormData) => {
    const forms = get(configs, ["forms"], []);
    const newForm = forms.find((form) => form.name === formName);
    setDefaultData(
      newForm.transformDefaultData
        ? { ...newForm.transformDefaultData(defaultFormData) }
        : { ...defaultFormData },
    );
    setForm(newForm);
  };
  const showDrawer = (formName, defaultFormData = {}) => {
    changeForm(formName, defaultFormData);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const submitForms = (data) => {
    form.transformDataBeforeSend
      ? dispatch(form.action(form.transformDataBeforeSend({ ...defaultData, ...data })))
      : dispatch(form.action({ ...defaultData, ...data }));
    onClose();
  };

  return (
    <Wrapper>
      <TableHeader data={configs.header} showDrawer={showDrawer} />
      <Drawer title="Basic Drawer" placement="right" onClose={onClose} open={open}>
        <Container>
          {renderStoreForm(form.btnTitle, get(form, ["fields"], []), submitForms, defaultData)}
        </Container>
      </Drawer>
      {children}
      <Table rowKey={"key"} dataSource={data} columns={configs.columns(showDrawer)} bordered />
    </Wrapper>
  );
};

const Container = styled.div`
  height: 100%;
  font-weight: 400;
`;

const Wrapper = styled.div``;

export default CustomTable;
