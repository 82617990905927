import { GET_PROFILE_SUCCESS, GET_PROFILE_FAIL } from "./types";

const initialState = {};
const isLoggedIn = false;

export default function (state = { ...initialState, isLoggedIn }, action = {}) {
  const { type, payload } = action;
  const data = payload?.data?.data || {};
  switch (type) {
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        ...data,
        isLoggedIn: true,
      };
    case GET_PROFILE_FAIL:
      return {
        isLoggedIn: false,
      };
    default:
      return state;
  }
}
