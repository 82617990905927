import { api } from "../configs/api";
import { RESTAURANT_URL } from "./urls";

const uploadFile = (formData) => {
  return api().post(RESTAURANT_URL + "/upload-image", formData);
};

const getStoreTotalData = () => {
  return api().get(RESTAURANT_URL + "/get-store-by-user-id");
};

const createStore = ({ name, phone, address, main_image, images }) => {
  return api().post(RESTAURANT_URL + "/store/add-store", {
    name,
    phone,
    address,
    main_image,
    images,
  });
};

const deleteDish = ({ _id }) => {
  return api().delete(RESTAURANT_URL + `/dishes/${_id}`);
};

const createDish = (data) => {
  return api().post(RESTAURANT_URL + "/dishes/create-dish", data);
};

const getAllDishes = () => {
  return api().get(RESTAURANT_URL + "/dishes");
};

const updateDish = (data) => {
  return api().post(RESTAURANT_URL + "/dishes/update-dish", data);
};

const getCartProducts = (data) => {
  return api().post(RESTAURANT_URL + "/get-list-products-by-ids", data);
};

const getDishesBytag = (tag) => {
  return api().get(RESTAURANT_URL + `/dishes/get-dishes-by-tag?tag=${tag}`);
};

export default {
  uploadFile,
  createStore,
  getStoreTotalData,
  deleteDish,
  createDish,
  getAllDishes,
  updateDish,
  getCartProducts,
  getDishesBytag,
};
