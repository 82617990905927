export const justLandingList = {
  additionalTitle: [
    "All",
    "Fruits & Vegetables",
    "Frozen Seafoods",
    "Raw Meats",
    "Coffes & Teas",
    "Pet Foods",
    "Milks & Dairies",
  ],
  title: "Just Landing",
  numberOfDisplayItems: 6,
  list: [
    {
      src: "/images/05_11a.jpg",
      name: "Bar S – Classic Bun Lead",
      star: 4,
      price: 35.0,
      _id: "12",
    },
    {
      src: "/images/07_2a.jpg",
      name: "Saute Pan Silver",
      star: 4.7,
      price: 1.9,
      _id: "11",
    },
    {
      src: "/images/08_1a-600x600.jpg",
      name: "Broccoli Crowns",
      star: 4.9,
      price: 10.0,
      _id: "1",
    },
    {
      src: "/images/08_1a-600x600.jpg",
      name: "Häagen-Dazs Salted Caramel",
      star: 4.3,
      price: 11.57,
      _id: "2",
    },
    {
      src: "/images/09_7a-600x600.jpg",
      name: "Iceland 3 Solo Exotic Burst",
      star: 4.2,
      price: 11.57,
      _id: "3",
    },
    {
      src: "/images/05_4a.jpg",
      name: "Slimming World Vegan Gre...",
      star: 4.5,
      price: 3.38,
      _id: "4",
    },
    {
      src: "/images/05_11a.jpg",
      name: "Bar S – Classic Bun Lead",
      star: 4,
      price: 35.0,
      _id: "5",
    },
    {
      src: "/images/07_2a.jpg",
      name: "Saute Pan Silver",
      star: 4.7,
      price: 1.9,
      _id: "6",
    },
    {
      src: "/images/08_1a-600x600.jpg",
      name: "Broccoli Crowns",
      star: 4.9,
      price: 10.0,
      _id: "7",
    },
    {
      src: "/images/08_1a-600x600.jpg",
      name: "Häagen-Dazs Salted Caramel",
      star: 4.3,
      price: 11.57,
      _id: "8",
    },
    {
      src: "/images/09_7a-600x600.jpg",
      name: "Iceland 3 Solo Exotic Burst",
      star: 4.2,
      price: 11.57,
      _id: "9",
    },
    {
      src: "/images/05_4a.jpg",
      name: "Slimming World Vegan Gre...",
      star: 4.5,
      price: 3.38,
      _id: "10",
    },
  ],
};
