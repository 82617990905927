import { MenuOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import styled from "styled-components";
import CategoriesMenu from "./CategoriesMenu";
import { categoriesListConfig } from "../../../configs/headers/listCategories";

const ShopByCategory = () => {
  return (
    <Dropdown
      // open={true}
      dropdownRender={() => <CategoriesMenu data={categoriesListConfig} />}
      menu={{ items: [] }}
      placement="bottomLeft">
      <ShopByCategoryBox>
        <MenuOutlined />
        <ShopByCategoryText>SHOP BY CATEGORY</ShopByCategoryText>
      </ShopByCategoryBox>
    </Dropdown>
  );
};

const ShopByCategoryBox = styled.div`
  padding: 13px 22px;
  background-color: #fab526;
  display: flex;
  border-radius: 5px;
  margin-right: 30px;
  align-items: center;
  cursor: pointer;
`;

const ShopByCategoryText = styled.span`
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 2px 0 0 18px;
  white-space: nowrap;
`;

export default ShopByCategory;
