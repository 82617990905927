import {
  CREATE_DISH,
  CREATE_STORE,
  CREATE_STORE_FAIL,
  DELETE_DISH,
  GET_STORE_TOTAL_DATA,
  GET_STORE_TOTAL_DATA_SUCCESS,
  UPDATE_DISH,
  GET_ALL_DISHES,
  GET_ALL_DISHES_SUCCESS,
} from "./types";
import { action } from "typesafe-actions";

export function createStoreAct({ name, phone, address, main_image, images }, navigate) {
  return action(CREATE_STORE, { name, phone, address, main_image, images, navigate });
}

export function createStoreFailAct(data) {
  return action(CREATE_STORE_FAIL, data);
}

export function getStoreTotalDataAct() {
  return action(GET_STORE_TOTAL_DATA);
}

export function getStoreTotalDataSuccessAct(data) {
  return action(GET_STORE_TOTAL_DATA_SUCCESS, data);
}

export function deleteDishAct({ _id }) {
  return action(DELETE_DISH, { _id });
}

export function createDishAct({ name, price, main_image, images, status, tags }) {
  return action(CREATE_DISH, { name, price: Number(price), main_image, images, status, tags });
}

export function updateDishAct({ name, price, main_image, images, status, _id }) {
  return action(UPDATE_DISH, { name, price: Number(price), main_image, images, status, _id });
}

export function getAllDishesAct() {
  return action(GET_ALL_DISHES);
}

export function getAllDishesSuccessAct(data) {
  return action(GET_ALL_DISHES_SUCCESS, data);
}
